import {NgRedux} from "@angular-redux/store";
import {Component, Input, OnInit} from '@angular/core';
import {ApplicationState} from "@features/state/application-state";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";

@Component({
    selector: 'app-shares-details',
    templateUrl: './shares-details.component.html'
})
export class SharesDetailsComponent implements OnInit {

    formGroup: FormGroup;
    @Input() maxShares = 100;
    @Input() controller: AbstractControl;

    constructor(
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            hasShares: ['', [Validators.required]]
        });
        this.controller.setValue(null);
        this.formGroup.controls['hasShares'].valueChanges.subscribe({
            next: (value) => {
                this.controller.clearValidators();
                this.formGroup.removeControl('sharesValue');
                if (value) {
                    this.controller.setValidators([
                        Validators.required,
                        (control: AbstractControl): ValidationErrors | null => {
                            return this.sharesValid(control.value) ? null : {sharesValue: {value: control.value}};
                        }
                    ]);
                    this.formGroup.addControl('sharesValue', this.controller);
                } else {
                    this.controller.setValue(0);
                }
            }
        });
    }

    sharesValid(shares: any): boolean {
        shares = shares == null ? 0 : parseInt(shares);
        return shares <= this.maxShares && (Number(this.totalShares) + Number(shares)) <= 100;
    }

    get totalShares(): number {
        let shares = [...this.state.moveRelatedParties]
            .map(e => e.sharePercentage)
            .reduce((a, e) => a + e, 0);
        return Number(shares) + Number(this.state.personalDetails.sharePercentage);
    }

    get moved(): string[] {
        return this.state.moveRelatedParties.map(e => e.idNumber);
    }

    get state(): ApplicationState {
        return this.store.getState();
    }

    get maxLength(): number {
        return this.maxShares > 50 ? 3 : 2;
    }
}
