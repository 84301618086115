<form [formGroup]="formGroup">
    <mat-form-field floatLabel="always" [appearance]="getAppearance('idNumber')">
        <mat-label>{{isPassport ? 'Passport number' : 'Personal ID number'}}</mat-label>
        <input matInput
               [readonly]="fieldHasValue('idNumber')"
               appFieldConstraint
               [fieldMaxLength]="13"
               fieldType="ONLY_NUMERIC"
               formControlName="idNumber"
               [control]="formGroup.controls['idNumber']"
               placeholder="Enter your personal ID number"/>
    </mat-form-field>
    <div *ngIf="isPassport">
        <app-passport-details
                [control]="formGroup.controls['passportDetails']">
        </app-passport-details>
    </div>
    <app-validation-error
            [controller]="formGroup.controls['idNumber']"
            [message]="'A valid ID number is required.'">
    </app-validation-error>
    <mat-form-field floatLabel="always" [appearance]="getAppearance('firstName')">
        <mat-label>First name</mat-label>
        <input matInput
               appFieldConstraint
               [fieldMaxLength]="80"
               [readonly]="fieldHasValue('firstName')"
               placeholder="Enter your first name"
               formControlName="firstName"
               fieldType="SPACE_ALPHA_NUMERIC"
               [control]="formGroup.controls['firstName']"/>
    </mat-form-field>
    <app-validation-error
            [controller]="formGroup.controls['firstName']"
            [message]="'First name is required.'">
    </app-validation-error>
    <mat-form-field floatLabel="always" [appearance]="getAppearance('lastName')">
        <mat-label>Last name</mat-label>
        <input matInput
               placeholder="Enter your last name"
               formControlName="lastName"
               [readonly]="fieldHasValue('lastName')"
               appFieldConstraint
               [fieldMaxLength]="80"
               fieldType="SPACE_ALPHA_NUMERIC"
               [control]="formGroup.controls['lastName']"/>
    </mat-form-field>
    <app-validation-error
            [controller]="formGroup.controls['lastName']"
            [message]="'Last name is required.'">
    </app-validation-error>
    <mat-form-field floatLabel="always">
        <mat-label>Gender</mat-label>
        <mat-select
                appStaticField
                [model]="model"
                formControlName="gender"
                placeholder="Select your gender"
                [compareWith]="compareOptions">
            <mat-option *ngFor="let item of modelGender | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Maritual status</mat-label>
        <mat-select
                appStaticField
                [model]="model"
                placeholder="Select your maritual status"
                [compareWith]="compareOptions"
                formControlName="maritalStatus">
            <mat-option *ngFor="let item of modelMaritualStatus | async" [value]="item.label">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Nationality</mat-label>
        <mat-select
                appStaticField
                [model]="model"
                placeholder="Select your nationality"
                formControlName="nationality"
                [compareWith]="compareOptions">
            <mat-option *ngFor="let item of modelNationality | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Country of birth</mat-label>
        <mat-select
                [compareWith]="compareOptions"
                placeholder="Select your country of birth"
                formControlName="countryOfBirth">
            <mat-option *ngFor="let item of modelCountryOfBirth |async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Country of residence</mat-label>
        <mat-select
                appStaticField
                [model]="model"
                [compareWith]="compareOptions"
                placeholder="Select your country of residence"
                formControlName="countryOfResidence">
            <mat-option *ngFor="let item of modelCountryOfResidence | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div *ngIf="!isSoleTrader()">
        <!--
        <mat-form-field floatLabel="always">
            <mat-label>Designation</mat-label>
            <mat-select
                    formControlName="roles"
                    placeholder="Select your designation"
                    [compareWith]="compareOptions">
                <mat-option *ngFor="let item of designations | async" [value]="item.code">
                    {{ item.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        -->
        <app-switch
                negativeLabel="No"
                positiveLabel="Yes"
                label="Are you an Authorised Signatory?"
                [control]="formGroup.controls['authorisedSignatory']">
        </app-switch>
        <app-shares-details
                [maxShares]="100"
                [controller]="formGroup.controls['sharePercentage']">
        </app-shares-details>
    </div>
    <!--
    NAV BUTTONS
    -->
    <div class="navigation-bottom">
        <button type="button" class="button-active" (click)="clickNext()"
                [disabled]="isInvalid()">
            Next
        </button>
        <br/>
    </div>
</form>
