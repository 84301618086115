import {Injectable} from '@angular/core';
import {Pages} from "@features/services/model/pages";
import {BehaviorSubject, map, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PagesControllerService extends BehaviorSubject<Pages> {
    readonly child$: Observable<Pages>;

    constructor() {
        super({
            applyPage: true,
        } as Pages);
        this.child$ = this.asObservable();
    }

    reset(): void {
        this.next({
            applyPage: true,
        } as Pages);
    }

    showQuestions(): void {
        this.next({
            questionsPage: true,
        } as Pages);
    }

    showAuthentication(): void {
        this.next({
            authenticatePage: true,
        } as Pages);
    }

    showStart(): void {
        this.next({
            startPage: true,
        } as Pages);
    }

    showResume(): void {
        this.next({
            resumePage: true,
        } as Pages);
    }

    showEntity(): void {
        this.next({
            entity: true,
        } as Pages);
    }

    showEntityParty(): void {
        this.next({
            entityParty: true,
        } as Pages);
    }

    showEntityRole(): void {
        this.next({
            entityRole: true,
        } as Pages);
    }

    showPartyDetails(): void {
        this.next({
            partyDetails: true,
        } as Pages);
    }

    showApply(): void {
        this.next({
            applyPage: true,
        } as Pages);
    }

    isStartPage(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.startPage;
            })
        );
    }

    isQuestionsPage(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.questionsPage;
            })
        );
    }

    isAuthenticationPage(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.authenticatePage;
            })
        );
    }

    isApplyPage(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.applyPage;
            })
        );
    }

    isResumePage(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.resumePage;
            })
        );
    }

    isEntity(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.entity;
            })
        );
    }

    isEntityParty(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.entityParty;
            })
        );
    }

    isEntityRole(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.entityRole;
            })
        );
    }

    isPartyDetails(): Observable<boolean> {
        return this.child$.pipe(
            map((pages: Pages) => {
                return pages.partyDetails;
            })
        );
    }
}
