import {Component, Input} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
    selector: 'app-validation-error',
    templateUrl: './validation-error.component.html'
})
export class ValidationErrorComponent {

    @Input() message: string;
    @Input() controller: AbstractControl;

    constructor() {
    }

    get hasError(): boolean {
        if (this.controller.dirty || this.controller.touched) {
            return (
                this.controller.errors?.['email'] ||
                this.controller.errors?.['pattern'] ||
                this.controller.errors?.['required'] ||
                this.controller.errors?.['sharesValue'] ||
                this.controller.hasError('minlength') ||
                this.controller.hasError('maxlength') ||
                this.controller.hasError('sharesValue')
            );
        } else {
            return false;
        }
    }
}
