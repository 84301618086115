<br>
<mat-divider></mat-divider>
<br>
<form [formGroup]="formGroup">
    <mat-form-field>
        <input matInput
               placeholder="Personal ID number"
               [formControlName]="getName('idNumber')"
               appStaticField [model]="getValue('idNumber')"/>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               placeholder="First Name"
               [formControlName]="getName('firstName')"
               appStaticField
               [model]="getValue('firstName')"/>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               placeholder="Last Name"
               [formControlName]="getName('lastName')"
               appStaticField [model]="getValue('lastName')"/>
    </mat-form-field>
</form>