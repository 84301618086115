import {Observable} from "rxjs";
import {select} from "@angular-redux/store";
import {Country} from "@features/models/country";
import {Component, Input, OnInit} from '@angular/core';
import {ApplicationState} from "@features/state/application-state";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";

@Component({
    selector: 'app-passport-details',
    templateUrl: './passport-details.component.html'
})
export class PassportDetailsComponent implements OnInit {

    formGroup: FormGroup;
    @Input() control: AbstractControl;
    @select((store: ApplicationState) => store.lookup.countriesOfOrigin) countries: Observable<Country[]>;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            expiryDate: ['', [Validators.required]],
            dateOfBirth: ['', [Validators.required]],
            dateOfIssue: ['', [Validators.required]],
            countryOfIssue: ['', [Validators.required]]
        });
        this.control.setValidators([
            (control: AbstractControl): ValidationErrors | null => {
                return this.formGroup.valid ? null : {required: {value: control.value}};
            }
        ]);
        this.formGroup.valueChanges.subscribe({
            next: (value) => {
                this.control.setValue(value);
            }
        })
    }

    get currentDate(): Date {
        return new Date();
    }
}
