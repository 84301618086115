<!--
<div class="notification-background">
    <div class="notification-icon-green">✓</div>
</div>
-->
<div class="notification-background">
    <div class="notification-icon-green">
        <img src="assets/icons/absa/success.svg"/>
    </div>
</div>
<div class="notification-title">
    Thank you for your application
</div>
<div>
    <div class="notification-details">
        Our team will get back to you shortly.
    </div>
    <br/>
    <div class="notification-details">
        Your reference number is
    </div>
    <div class="notification-details-string">
        {{ref |  async}}
    </div>
    <br/>
    <div class="notification-details">
        We've sent you a confirmation of your application, please check your email.
    </div>
    <br/>
    <div class="navigation-bottom">
        <button type="button" class="button-active" (click)="clickNext()">Done</button>
    </div>
</div>