<form [formGroup]="formGroup">
    <!--
     BUSINESS DETAILS
    -->
    <mat-form-field floatLabel="always">
        <mat-label>Trading name</mat-label>
        <input matInput
               placeholder="Enter your trading name"
               formControlName="tradingName"
               appFieldConstraint
               [fieldMaxLength]="80"
               fieldType="SPACE_ALPHA_NUMERIC"
               [control]="formGroup.controls['tradingName']"/>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Nature of business</mat-label>
        <mat-select
                [compareWith]="compareOptions"
                placeholder="Select your nature of business"
                formControlName="natureOfBusiness"
                (valueChange)="divisionSelected($event)">
            <mat-option *ngFor="let item of modelNatureOfBusiness | async" [value]="item.code">
                {{ item.description}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Division</mat-label>
        <mat-select
                placeholder="Select your business division"
                formControlName="division"
                [compareWith]="compareOptions"
                (valueChange)="subdivisionSelected($event) ">
            <mat-option *ngFor="let item of this.modelDivisions" [value]="item.code">
                {{ item.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Sub division</mat-label>
        <mat-select
                placeholder="Select your business sub division"
                formControlName="subDivision"
                [compareWith]="compareOptions"
                (valueChange)="operationSelected($event)">
            <mat-option *ngFor="let item of this.modelSubDivisions" [value]="item.code">
                {{ item.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Operation</mat-label>
        <mat-select
                placeholder="Select your business operation"
                formControlName="operation"
                [compareWith]="compareOptions">
            <mat-option *ngFor="let item of this.modelOperations" [value]="item.code">
                {{ item.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Country of operation</mat-label>
        <mat-select
                [compareWith]="compareOptions"
                placeholder="Select your country of operation"
                formControlName="countryOfOperation">
            <mat-option *ngFor="let item of modelCountryOfOperations | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <!--
        REGISTERED ADDRESS
    -->
    <br>
    <div class="business-details-header">Business Address</div>
    <br>
    <mat-form-field floatLabel="always">
        <mat-label>Complex/Building name</mat-label>
        <input matInput
               formControlName="registeredComplex"
               placeholder="Enter your complex/building name"
               appFieldConstraint
               [fieldMaxLength]="80"
               fieldType="SPACE_ALPHA_NUMERIC"
               [control]="formGroup.controls['registeredComplex']"/>
    </mat-form-field>
    <app-address-picker (address)="onRegisteredAddress($event)" [value]="registeredAddressValue"></app-address-picker>
    <!--
    HEAD OFFICE ADDRESS
    -->
    <app-switch
            negativeLabel="No"
            positiveLabel="Yes"
            [defaultValue]="true"
            [labelHint]="addressHolder?.physicalAddress"
            label="Is your head office address the same as your registered address?"
            [control]="formGroup.controls['headOfficeQuestion']">
    </app-switch>
    <div *ngIf="!formGroup.controls['headOfficeQuestion'].value">
        <mat-form-field floatLabel="always">
            <mat-label>Complex/Building name</mat-label>
            <input matInput
                   formControlName="officeComplex"
                   placeholder="Enter your complex/building name"
                   appFieldConstraint
                   [fieldMaxLength]="80"
                   fieldType="SPACE_ALPHA_NUMERIC"
                   [control]="formGroup.controls['officeComplex']"/>
        </mat-form-field>
        <app-address-picker (address)="onOfficeAddress($event)"></app-address-picker>
    </div>
    <!--
      TRADING ADDRESS
    -->
    <app-switch
            negativeLabel="No"
            positiveLabel="Yes"
            [defaultValue]="true"
            [labelHint]="addressHolder?.physicalAddress"
            label="Is your postal address the same as your business address?"
            [control]="formGroup.controls['tradingQuestion']">
    </app-switch>
    <div *ngIf="!formGroup.controls['tradingQuestion'].value">
        <mat-form-field floatLabel="always">
            <mat-label>Complex/Building name</mat-label>
            <input matInput
                   formControlName="tradingComplex"
                   placeholder="Enter your complex/building name"
                   appFieldConstraint
                   [fieldMaxLength]="80"
                   fieldType="SPACE_ALPHA_NUMERIC"
                   [control]="formGroup.controls['tradingComplex']"/>
        </mat-form-field>
        <app-address-picker (address)="onTradingAddress($event)" [value]="tradingAddressValue"></app-address-picker>
    </div>
    <!--
        END
    -->
    <div class="navigation-bottom">
        <button type="button"
                class="button-active"
                (click)="clickNext()"
                [disabled]="isInvalid()">Next
        </button>
        <br/>
        <br/>
    </div>
</form>