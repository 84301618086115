import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Title} from '@angular/platform-browser';
import {Component, OnInit} from '@angular/core';
import {doNothing} from "@features/services/helper";
import {NgRedux, select} from "@angular-redux/store";
import {Endpoints} from "@features/services/endpoints";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {EntityType} from "@features/models/entity-type";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from '@features/services/features.service';
import {PagesControllerService} from "@features/services/pages-controller.service";
import {LOOKUPS, RESET_APPLICATION, APPLICATION_INFORMATION} from "@features/state/actions";

@Component({
    selector: 'app-onboarding-view',
    templateUrl: './onboarding-view.component.html',
})
export class OnboardingViewComponent implements OnInit {

    processing$ = this.spinner.loading$;
    @select((store: ApplicationState) => store.clientType) entityType: Observable<EntityType>;

    constructor(
        private title: Title,
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private store: NgRedux<ApplicationState>,
        private controller: PagesControllerService,
    ) {
    }

    ngOnInit() {
        this.controller.reset();
        this.store.dispatch(RESET_APPLICATION());
        this.service.loadApplicationConfig(Endpoints.CONFIG_PATH)
            .subscribe(
                {
                    next: (res) => {
                        const {applicationTitle} = res;
                        this.title.setTitle(applicationTitle);
                        this.store.dispatch(APPLICATION_INFORMATION(res));
                        this.service.getLookupInformation()
                            .subscribe({
                                next: (res) => {
                                    this.store.dispatch(LOOKUPS(res));
                                },
                                error: () => {
                                    this.router.navigate(['error'], {queryParams: {code: 'setup-data'}}).then(doNothing);
                                }
                            })
                    },
                    error: () => {
                        this.router.navigate(['error'], {queryParams: {code: 'setup-data'}}).then(doNothing);
                    }
                }
            );
    }

    isCompany(entityType: EntityType): boolean {
        if (entityType && entityType.id) {
            return !FormHelper.isSoleProprietor(entityType);
        } else {
            return false;
        }
    }

}
