import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {doNothing} from "@features/services/helper";
import {BehaviorSubject, Observable, of} from "rxjs";
import {NgRedux, select} from "@angular-redux/store";
import {Endpoints} from "@features/services/endpoints";
import {EntityType} from "@features/models/entity-type";
import {FormHelper} from "@features/helper/form-helper";
import {AuthRequest} from "@features/models/auth-request";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {PagesControllerService} from "@features/services/pages-controller.service";
import {APPLICATION_INFORMATION, AUTHENTICATION_MODEL, ENTITY} from "@features/state/actions";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";

@Component({
    selector: 'app-entity',
    templateUrl: './entity.component.html'
})
export class EntityComponent extends BehaviorSubject<boolean> implements OnInit {

    formGroup: FormGroup;
    registrationNumber$ = this.asObservable();

    @select((store: ApplicationState) => store.lookup.entityTypes) _entities: Observable<EntityType[]>;

    constructor(
        private router: Router,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
        public controller: PagesControllerService,
    ) {
        super(false);
    }

    ngOnInit(): void {
        let product = this.store.getState().product?.name;
        this.formGroup = this.builder.group({
            product: [product],
            entityType: ['', [Validators.required]]
        });
        this.formGroup.controls['entityType'].valueChanges.subscribe({
            next: (id) => {
                let entity = {id: id} as EntityType
                this.next(!FormHelper.isSoleProprietor(entity));
                if (FormHelper.isSoleProprietor(entity)) {
                    this.formGroup.removeControl('registrationNumber')
                    this.formGroup.removeControl('authorisedToOpenAccount')
                } else {
                    this.formGroup.addControl('registrationNumber', this.builder.control('', [
                            ...FormHelper.isRegisteredPTY(entity) ? FormHelper.PTY_COMPANY_REGISTRATION_NUMBER_VALIDATION :
                                FormHelper.CC_COMPANY_REGISTRATION_NUMBER_VALIDATION,
                        ]
                    ));
                    this.formGroup.addControl('authorisedToOpenAccount', this.builder.control('', [
                            (control: AbstractControl): ValidationErrors | null => {
                                return control.value && control.value == true ?
                                    null : {authorisedToOpenAccount: {value: control.value}};
                            }
                        ]
                    ));
                }
            }
        });
    }

    clickNext() {
        let value = this.formGroup.getRawValue();
        let product = this.store.getState().lookup.entityTypes.find((e: EntityType) => e.id == value.entityType);
        this.store.dispatch(ENTITY(product));
        if (value.registrationNumber) {
            this.service.validateCompanyRegistrationNumber(value.registrationNumber).subscribe({
                next: () => {
                    this.store.dispatch(AUTHENTICATION_MODEL({
                        registrationNumber: value.registrationNumber,
                    } as AuthRequest));
                    this.loadConfig();
                },
                error: (err) => {
                    if (err.status == 307) {
                        this.router.navigate(['contact-me']).then(doNothing);
                    } else {
                        this.router.navigate(['error']).then(doNothing);
                    }
                }
            });
        } else {
            this.controller.showStart();
        }
    }

    clickPrevious() {
        this.controller.showApply();
    }

    isInvalid(): boolean {
        return !this.formGroup.valid || (!this.formGroup.dirty && this.formGroup.touched);
    }

    get entities(): Observable<EntityType[]> {
        if (FormHelper.isMock()) {
            return of([
                ...this.store.getState().lookup.entityTypes.filter(e => FormHelper.isSoleProprietor(e))
            ]);
        } else {
            return this._entities;
        }
    }

    getAppearance(): any {
        return 'none';
    }

    loadConfig(): void {
        this.service.loadApplicationConfig(Endpoints.COMPANY_CONFIG_PATH).subscribe({
            next: (res) => {
                this.store.dispatch(APPLICATION_INFORMATION(res));
                this.controller.showStart();
            },
            error: (err) => {
                console.log('---FAILED TO LOAD CONFIG:', err, Endpoints.COMPANY_CONFIG_PATH);
            }
        })
    }
}
