import {NgRedux} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {NEXT_STEP, PREVIOUS_STEP} from "@features/state/actions";
import {ApplicationState} from "@features/state/application-state";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-step-eleven',
    templateUrl: './step-eleven.component.html',
})
export class StepElevenComponent implements OnInit {

    formGroup!: FormGroup;

    constructor(
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.formGroup = this.builder.group({});
    }

    clickNext(): void {
        this.store.dispatch(NEXT_STEP());
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }
}
