import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {doNothing} from "@features/services/helper";
import {NgRedux, select} from "@angular-redux/store";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {BankAccount} from "@features/models/bank-account";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {ProductAndPricing} from "@features/models/product-and-pricing";
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';
import {
    AccountPurpose,
    BankName,
    BusinessEvolvePricePlan,
    CountriesOfResidence,
    Product
} from "@features/models/lookup.model";
import {
    NEXT_STEP,
    FINANCIAL_DETAILS,
    UPDATE_ACCOUNT_OPTIONS,
    PREVIOUS_STEP, PRODUCT_PRICING, STEPPER_HEADER, EVOLVE_DETAILS,
} from "@features/state/actions";

@Component({
    selector: 'app-step-seven',
    templateUrl: './step-seven.component.html'
})
export class StepSevenComponent extends BehaviorSubject<number> implements OnInit {

    bank: BankAccount;
    evolveProduct: Product;
    page$ = this.asObservable();
    plan: BusinessEvolvePricePlan;

    isIslamic = false;
    formGroup!: FormGroup;
    debitsFormGroup!: FormGroup;
    creditsFormGroup!: FormGroup;
    stepHeader: string = 'Bank Account Detail';

    @select((store: ApplicationState) => store.hasEvolve) hasEvolve: Observable<boolean>;
    @select((store: ApplicationState) => store.lookup.bankNames) banks: Observable<BankName[]>;
    @select((store: ApplicationState) => store.lookup.products) products: Observable<Product[]>;
    @select((store: ApplicationState) => store.evolveDeposit) evolveDeposit: Observable<boolean>;
    @select((store: ApplicationState) => store.lookup.accountPurposes) accountPurposes: Observable<AccountPurpose[]>;
    @select((store: ApplicationState) => store.lookup.countriesOfOrigin) countries: Observable<CountriesOfResidence[]>;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private builder: FormBuilder,
        private service: FeaturesService,
        private store: NgRedux<ApplicationState>,
    ) {
        super(0);
        this.bank = this.store.getState().financialDetails?.paymentPlan || new BankAccount();
    }

    ngOnInit(): void {
        this.updateHeading(this.stepHeader);
        this.formGroup = this.builder.group({
            bankQuestion: [this.state.hasEvolve, []],
            evolveQuestion: [this.state.evolveDeposit, []],
            openEvolveQuestion: [this.state.openEvolve, []],
            islamicEvolveQuestion: [this.state.islamicEvolve, []],
            bankName: [this.bank.bankName, [Validators.required]],
            branchCode: [this.bank.branchCode, [Validators.required]],
            branchName: [this.bank.branchName, [Validators.required]],
            accountName: [this.bank.accountName, [Validators.required]],
            accountNumber: [this.bank.accountNumber, [...FormHelper.ACCOUNT_VALIDATIONS]],
        });
        let controls = [
            this.formGroup.controls['bankName'],
            this.formGroup.controls['branchCode'],
            this.formGroup.controls['branchName'],
        ];
        let bankQuestion = this.formGroup.controls['bankQuestion'];
        let evolveQuestion = this.formGroup.controls['evolveQuestion'];
        bankQuestion.valueChanges.subscribe({
            next: (value: boolean) => {
                this.store.dispatch(UPDATE_ACCOUNT_OPTIONS({
                    hasEvolve: value,
                    evolveDeposit: false,
                }));
                this.selectAbsaEvolve(value, controls);
            }
        });
        evolveQuestion.valueChanges.subscribe({
            next: (value: boolean) => {
                this.store.dispatch(UPDATE_ACCOUNT_OPTIONS({
                    hasEvolve: this.store.getState().hasEvolve,
                    evolveDeposit: value,
                }));
            }
        });
        //-------- ADDITIONAL FORMS -----//
        this.creditsFormGroup = this.builder.group({
            receiveMoney: ['', Validators.required],
            sourceOfMoney: ['', Validators.required],
            accountPurpose: ['', Validators.required],
            valueOfCreditsPerMonth: ['', Validators.required],
            numberOfCreditsPerMonth: ['', Validators.required],
        });
        this.debitsFormGroup = this.builder.group({
            staffPayDate: ['', Validators.required],
            supplierCountry: ['', Validators.required],
            valueOfDebitOrders: ['', Validators.required],
            reasonOfDebitOrders: ['', Validators.required],
            numberOfStaffMembers: ['', Validators.required],
            numberOfDebitsPerMonth: ['', Validators.required],
            numberOfSupplierPaymentPerMonth: ['', Validators.required],
        });
    }

    clickNext(): void {
        this.updateHeading('BUSINESS EVOLVE');
        let value = this.formGroup.getRawValue();
        this.isIslamic = value.islamicEvolveQuestion;
        if (value.openEvolveQuestion) {
            this.updateHeading('Business Evolve');
            this.next(1);
        } else {
            this.normalAccountDetails(value);
        }
    }

    normalAccountDetails(value: any): void {
        let financialDetail = this.state.financialDetails;
        let account: BankAccount = {
            bankName: value.bankName,
            branchCode: value.branchCode,
            branchName: value.branchName,
            accountName: value.accountName,
            accountNumber: value.accountNumber,
            accountType: value.accountType || 'Cheque',
        };
        this.store.dispatch(FINANCIAL_DETAILS({
            ...financialDetail,
            paymentPlan: account,
        }));
        let request = this.store.getState().financialDetails;
        this.processPaymentDetails(request);
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    private getProductPricing(): void {
        this.service.getMerchantDetail('off').subscribe({
            next: (res) => {
                this.spinner.hide();
                this.store.dispatch(PRODUCT_PRICING(res));
                this.store.dispatch(NEXT_STEP())
            },
            error: (err) => {
                this.spinner.hide();
                if (err.status == 404) {
                    this.store.dispatch(PRODUCT_PRICING({} as ProductAndPricing));
                    this.store.dispatch(NEXT_STEP())
                } else {
                    this.router.navigate(['error']).then(doNothing);
                }
            }
        });
    }

    selectAbsaEvolve(value: boolean, controls: AbstractControl[] = []) {
        if (value == true) {
            let banks = this.store.getState().lookup.bankNames;
            this.bank.bankName = banks.find(e => e.description == 'ABSA').description;
            this.formGroup.patchValue({
                ...this.store.getState().financialDetails?.paymentPlan || {},
                branchCode: '6300005',
                branchName: 'Universal',
                bankName: banks.find(e => e.description == 'ABSA').description,
            });
        }
        controls.forEach(control => {
            if (value == true) {
                control.disable()
            } else {
                control.enable()
            }
        });
    }

    compareOptions(object: any, selected: any): boolean {
        return object != null && selected != null && object == selected;
    }

    productFilter(product: Product, isIslamic: boolean): boolean {
        if (isIslamic === true) {
            return product.section == 'BUSINESS_EVOLVE' && product.type.indexOf('Islamic') > -1;
        } else {
            return product.section == 'BUSINESS_EVOLVE';
        }
    }

    plans(): BusinessEvolvePricePlan[] {
        this.evolveProduct =
            [...this.state.lookup.products]
                .filter(e => this.productFilter(e, this.isIslamic)).pop();
        return this.evolveProduct.businessEvolvePricePlans
    }

    selectPlan(plan: BusinessEvolvePricePlan) {
        this.plan = plan;
        this.updateHeading(this.evolveProduct.name);
        this.next(2);
    }

    applyBusinessEvolve() {
        let data = {
            ...this.store.getState().evolveDetails,
            ...this.debitsFormGroup.getRawValue()
        };
        this.store.dispatch(FINANCIAL_DETAILS({
            ...this.state.financialDetails,
            accountActivity: {
                creditsReceived: data.receiveMoney,
                staffPaymentDate: data.staffPayDate,
                accountPurpose: data.accountPurpose,
                noOfStaff: data.numberOfStaffMembers,
                creditsComingFrom: data.sourceOfMoney,
                valueOfDebits: data.valueOfDebitOrders,
                noOfDebits: data.numberOfDebitsPerMonth,
                supplierLocation: [data.supplierCountry],
                debitOrderReason: data.reasonOfDebitOrders,
                creditsPerMonth: data.valueOfCreditsPerMonth,
                expectedCreditsPerMonth: data.numberOfCreditsPerMonth,
                supplierPayments: data.numberOfSupplierPaymentPerMonth,
            },
            paymentPlan: {
                bankName: '',
                branchCode: '',
                branchName: '',
                accountType: '',
                accountName: '',
                accountNumber: '',
            }
        }));
        this.processPaymentDetails(this.state.financialDetails);
    }

    processPaymentDetails(request: any) {
        this.spinner.show();
        this.service.sendFinancialDetail(request, 'off').subscribe({
            next: () => {
                this.getProductPricing();
            },
            error: (err) => {
                if (err.status == 200 || err.status == 201) {
                    this.getProductPricing();
                } else {
                    this.spinner.hide();
                    this.router.navigate(['error']).then(doNothing);
                }
            }
        });
    }

    updateHeading(title: string) {
        this.store.dispatch(STEPPER_HEADER(title));
    }

    get state(): ApplicationState {
        return this.store.getState();
    }

    viewAllProducts() {
        this.updateHeading(this.stepHeader);
        this.next(1);
    }

    viewCreditsForm() {
        this.updateHeading('Credits On Account');
        this.next(3);
    }

    viewDebitsForm() {
        this.store.dispatch(EVOLVE_DETAILS(this.creditsFormGroup.getRawValue()));
        this.updateHeading('Debits On Account');
        this.next(4);
    }

    selectionsInvalid(): boolean {
        if (this.formValue('bankQuestion') === null
            || (this.formValue('bankQuestion') === true
                && this.formValue('evolveQuestion') === null)) {
            return true;
        }
        if (this.formValue('evolveQuestion') != null) {
            return this.formGroup.invalid;
        }
        return this.formValue('islamicEvolveQuestion') === null;
    }

    formValue(key: string): boolean | undefined {
        let value = this.formGroup.controls[key].value;
        return value === '' ? null : value;
    }

    showAccountForm(): boolean {
        if (this.formValue('bankQuestion') === true) {
            return this.formValue('evolveQuestion') != null
        } else {
            return this.formValue('openEvolveQuestion') === false;
        }
    }
}
