<div *ngIf="(toggle$ | async)===false">
    <div class="layout-container">
        <div class="notification-background">
            <div class="notification-icon-red">✗</div>
        </div>
        <div class="notification-title">
            Let us call you
        </div>
        <p>
            Provide us with your updated details and we will have the right
            banker to contact you to understand your business needs.
        </p>
        <p>
            Allowing us to complete your application and partner on the right
            solution for your business.
        </p>
        <p>
            Please request a call me back or contact us on 0860 111 222
        </p>
        <button type="button"
                class="button-active"
                (click)="contactMe()">
            Call me back
        </button>
        <br/>
        <br/>
        <button type="button"
                class="button-inactive"
                (click)="cancel()">
            Done
        </button>
    </div>
</div>
<div *ngIf="toggle$ | async">
    <button mat-fab
            class="navigation-top"
            aria-label="< Previous Step"
            (click)="cancel()">
        <mat-icon svgIcon="wizzit_left_arrow"></mat-icon>
        <mat-label class="navigation-header">Contact Details</mat-label>
    </button>
    <div class="layout-container">
        <div class="row">
            <div class="col-12">
                <br/>
                <br/>
                <form [formGroup]="formGroup">
                    <mat-form-field>
                        <input matInput
                               appFieldConstraint
                               placeholder="Name"
                               [fieldMaxLength]="35"
                               formControlName="firstName"
                               fieldType="SPACE_ALPHA_NUMERIC"
                               [control]="formGroup.controls['firstName']"/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput
                               appFieldConstraint
                               placeholder="Surname"
                               [fieldMaxLength]="35"
                               formControlName="lastName"
                               fieldType="SPACE_ALPHA_NUMERIC"
                               [control]="formGroup.controls['lastName']"/>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput

                               appFieldConstraint
                               [fieldMaxLength]="14"
                               mask="(000) 000-0000"
                               fieldType="ONLY_NUMERIC"
                               placeholder="Contact number"
                               formControlName="mobileNumber"
                               [control]="formGroup.controls['mobileNumber']"/>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select placeholder="When is the best time to contact you"
                                    formControlName="contactTime">
                            <mat-option *ngFor="let item of times" [value]="item">
                                {{ item }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="navigation-bottom">
                        <button type="button"
                                class="button-active"
                                (click)="submitContactForm()"
                                [disabled]="isInvalid()">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
