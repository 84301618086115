import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ErrorViewComponent} from "@core/views/error/error-view.component";
import {SuccessComponent} from "@features/views/success/success.component";
import {ContactMeComponent} from "@features/views/contact-me/contact-me.component";
import {OnboardingViewComponent} from '@features/views/onboarding/onboarding-view.component';
import {UploadDebugComponent} from "@features/views/upload-debug/upload-debug.component";

const routes: Routes = [
    {
        path: '',
        component: OnboardingViewComponent,
    },
    {
        path: 'contact-me',
        component: ContactMeComponent,
    },
    {
        path: 'success',
        component: SuccessComponent,
    },
    {
        path: 'error',
        component: ErrorViewComponent,
    },
    {
        path: 'files-debug',
        component: UploadDebugComponent,
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeaturesRoutingModule {
}
