import {map, Observable} from "rxjs";
import {Component} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {routeAnimations} from '@core/animations/route.animations';
import {PagesControllerService} from "@features/services/pages-controller.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-root',
    animations: [routeAnimations],
    template: `
        <body [class]="clazz | async">
        <router-outlet></router-outlet>
        </body>`
})
export class AppComponent {

    iconPath = '../../../../assets/icons/google/';

    constructor(
        private router: Router,
        private sanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private controller: PagesControllerService,
    ) {
        this.initIcons([
            {name: 'wizzit_camera', value: 'camera'},
            {name: 'wizzit_upload', value: 'upload'},
            {name: 'wizzit_settings', value: 'settings'},
            {name: 'wizzit_left_arrow', value: 'left_arrow_fill'},
            {name: 'wizzit_back_arrow', value: 'absa-back-arrow'},
            {name: 'wizzit_more_arrow', value: 'arrow_forward_ios'},
        ]);
    }

    initIcons(icons: { name: string, value: string }[]): void {
        icons.forEach(e => {
            let safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}${e.value}.svg`);
            this.matIconRegistry.addSvgIcon(e.name, safeResourceUrl);
        });
    }

    get clazz(): Observable<string> {
        return this.controller.isApplyPage().pipe(map(value => {
            let path = this.router.url.split('/').pop();
            if (path == '') {
                return value == true ? 'start-bg' : ''
            } else {
                return '';
            }
        }));
    }
}
