import {Component, Input} from '@angular/core';
import {ErrorMessage} from "@features/services/model/error-message";

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html'
})
export class ErrorComponent {
    @Input() message: ErrorMessage;

}
