import {Router} from "@angular/router";
import {NgRedux} from "@angular-redux/store";
import {doNothing} from "@features/services/helper";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PagesControllerService} from "@features/services/pages-controller.service";

@Component({
    selector: 'app-party-details',
    templateUrl: './party-details.component.html'
})
export class PartyDetailsComponent implements OnInit {

    @Output() partyDetails = new EventEmitter<boolean>();
    formGroup: FormGroup;

    constructor(
        private router: Router,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
        public controller: PagesControllerService,
    ) {
    }

    ngOnInit(): void {
        let party = this.store.getState().personalDetails;
        this.formGroup = this.builder.group({
            gender: [party.gender],
            idType: [party.idType],
            idNumber: [party.idNumber],
            lastName: [party.lastName],
            firstName: [party.firstName],
            nationality: [party.nationality],
            maritalStatus: [party.maritalStatus],
            countryOfBirth: [party.countryOfBirth],
        });
        Object.keys(this.formGroup.controls).forEach(key => {
            this.formGroup.controls[key].disable();
        });
    }

    clickNext() {
        this.partyDetails.next(true);
    }

    clickPrevious() {
        this.router.navigate(['error'], {queryParams: {code: 'information-wrong'}}).then(doNothing);
    }
}