<div *ngIf="(hasBack | async)===true">
    <button
            style="background-color: #e3e2e2!important; top: 0!important; position: fixed!important; z-index: 2!important; border-bottom:0px !important;"
            *ngIf="(((stepCurrent | async) >= 1) && ((stepCurrent | async) <= ((stepArray | async).length)))"
            mat-fab
            class="navigation-top"
            aria-label="< Previous Step"
            (click)="navigateToPreviousStep()">
        <mat-icon svgIcon="wizzit_back_arrow" class="back-arrow"></mat-icon>
        <mat-label class="navigation-header">{{ headerText | async }}</mat-label>
    </button>
</div>
<div *ngIf="(hasBack | async)===false">
    <button
            style="background-color: #e3e2e2!important; top: 0!important; position: fixed!important; z-index: 2!important; border-bottom:0px !important;"
            *ngIf="(((stepCurrent | async) >= 1) && ((stepCurrent | async) <= ((stepArray | async).length)))"
            mat-fab
            class="navigation-top">
        <mat-label class="navigation-header">{{ headerText | async }}</mat-label>
    </button>
</div>

