<form [formGroup]="formGroup">
    <mat-form-field floatLabel="always">
        <mat-label>Complex/Building name</mat-label>
        <input matInput
               maxlength="35"
               formControlName="street"
               placeholder="Enter your complex/building name"
               appFieldConstraint
               [fieldMaxLength]="80"
               fieldType="SPACE_ALPHA_NUMERIC"
               [control]="formGroup.controls['street']"/>
    </mat-form-field>
    <app-address-picker (address)="onAddress($event)" [value]="value"></app-address-picker>
    <div class="navigation-bottom">
        <button type="button" class="button-active" (click)="clickNext()" [disabled]="isInvalid()">Next</button>
        <br/>
        <br/>
    </div>
</form>