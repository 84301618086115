<div style="padding-top: 8px;">
    <div class="notification-background" *ngIf="message.code!=='error'">
        <img src="assets/icons/absa/failure.svg"/>
    </div>
    <div style="text-align: center;" *ngIf="message.code==='error'">
        <img class="settings-icon" src="assets/icons/google/Illustration.svg">
    </div>
    <div class="notification-title">
        {{message.header}}
    </div>
    <p class="notification-details " *ngFor="let details of message.details">
        {{details}}
    </p>
    <br>
    <div class="navigation-bottom">
        <button class="button-active" routerLink="/">Continue</button>
    </div>
</div>