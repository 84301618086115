import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {doNothing} from "@features/services/helper";
import {NgRedux, select} from "@angular-redux/store";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {MerchantClass} from "@features/models/merchant-class";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {ProductAndPricing} from "@features/models/product-and-pricing";
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DOCUMENTS_REQUIRED, NEXT_STEP, PREVIOUS_STEP, PRODUCT_PRICING} from "@features/state/actions";

@Component({
    selector: 'app-step-eight',
    templateUrl: './step-eight.component.html'
})
export class StepEightComponent implements OnInit {

    formGroup!: FormGroup;
    @select((store: ApplicationState) => store.lookup.merchantClass) modelMerchantClass: Observable<MerchantClass[]>;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        const model = this.store.getState().productPricing;
        this.formGroup = this.builder.group({
            terminalRentalFee: [this.getRandPricing('1'), []],
            internationalCards: [this.getPricing('4'), []],
            merchantClass: [model.merchantClass, [Validators.required]],
            masterVisaDebitCardPercentage: [this.getPricing('2'), []],
            masterVisaCreditCardPercentage: [this.getPricing('3'), []],
        });
        [
            'terminalRentalFee', 'internationalCards',
            'masterVisaDebitCardPercentage', 'masterVisaCreditCardPercentage'
        ].forEach(key => {
            //this.formGroup.controls[key].disable();
        });
    }

    getPricing(code: string): string {
        return `${parseFloat(this.getPricingValue(code)).toFixed(2)}%`;
    }
    getRandPricing(code: string): string {
        return `R ${parseFloat(this.getPricingValue(code)).toFixed(2)}`;
    }

    getPricingValue(code: string): string {
        const model = this.store.getState().lookup.MerchantFeeCategories;
        let fee = model.find(e => e.code == code)?.fee || '0.00';
        return fee;
    }

    clickNext(): void {
        let merchantClass = this.formGroup.getRawValue().merchantClass;
        let values: any = {
            terminalRentalFee: this.getPricingValue('1'),
            internationalCards: this.getPricingValue('4'),
            masterVisaDebitCardPercentage: this.getPricingValue('2'),
            masterVisaCreditCardPercentage: this.getPricingValue('3')
        }
        let pricing: ProductAndPricing = {
            ...this.store.getState().productPricing,
            ...values,
            merchantClass: merchantClass
        };
        this.store.dispatch(PRODUCT_PRICING(pricing));
        this.spinner.show();
        this.service.sendMerchantDetail(pricing, 'off').subscribe(
            {
                next: () => {
                    this.getDocuments();
                },
                error: (err) => {
                    if (err.status == 200 || err.status == 201) {
                        this.getDocuments();
                    } else {
                        this.spinner.hide();
                        this.router.navigate(['error']).then(doNothing);
                    }
                }
            }
        )
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }

    getDocuments(): void {
        this.service.getDocuments('off').subscribe({
            next: (res) => {
                this.spinner.hide();
                this.store.dispatch(DOCUMENTS_REQUIRED(res));
                this.store.dispatch(NEXT_STEP());
            },
            error: () => {
                this.spinner.hide();
                this.router.navigate(['error']).then(doNothing);
            }
        });
    }

    compareOptions(object: any, selected: any): boolean {
        return object != null && selected != null && object == selected;
    }

    getAppearance(): any {
        return 'none';
    }
}
