<div class="general-header">{{plan.price_plan}} per month.</div>
<div>{{plan.name}}</div>
<br/>
<div class="general-header">Qualifying criteria:</div>
<ul>
    <li>{{plan.annual_turnover}}</li>
</ul>
<mat-divider></mat-divider>
<br/>
<div class="general-header">Standard account features:</div>
<ul *ngFor="let term of terms">
    <li>{{term.name}}</li>
</ul>