import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {Country} from "@features/models/country";
import {Address} from "@features/models/address";
import {doNothing} from "@features/services/helper";
import {NgRedux, select} from "@angular-redux/store";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {AddressHolder} from "@features/models/address-holder";
import {GoogleAddress} from "@features/models/google-address";
import {Division, MajorGroup} from "@features/models/division";
import {OperationModel} from "@features/models/operation.model";
import {FinancialDetail} from "@features/models/financial-detail";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {NatureOfBusiness} from "@features/models/nature-of-business";
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {
    NEXT_STEP,
    PREVIOUS_STEP,
    ADDRESS_DETAIL,
    UPDATE_ADDRESS,
    BUSINESS_DETAILS,
    FINANCIAL_DETAILS,
} from "@features/state/actions";

@Component({
    selector: 'app-step-five',
    templateUrl: './step-five.component.html'
})
export class StepFiveComponent implements OnInit {

    formGroup: FormGroup;
    modelDivisions: Division[] = [];
    modelSubDivisions: MajorGroup[] = [];
    modelOperations: OperationModel[] = [];

    Physical = 'Physical';
    TradingAddress = 'Trading';
    addressHolder: AddressHolder;
    BusinessAddress = 'Head Office';
    RegisteredAddress = 'Registered';

    @select((store: ApplicationState) => store.lookup.countriesOfOrigin) modelCountryOfOperations: Observable<Country[]>;
    @select((store: ApplicationState) => store.lookup.natureOfBusiness) modelNatureOfBusiness: Observable<NatureOfBusiness[]>;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        let model = this.store.getState().businessDetails;
        this.addressHolder = this.store.getState().addressHolder;
        let tradingAddress = this.getAddress(this.TradingAddress);
        let businessAddress = this.getAddress(this.BusinessAddress);
        let registeredAddress = this.getAddress(this.RegisteredAddress);

        this.formGroup = this.builder.group({
            //----- BUSINESS DETAILS--//
            division: [model.division, [Validators.required]],
            operation: [model.operation, [Validators.required]],
            subDivision: [model.subDivision, [Validators.required]],
            tradingName: [model.tradingName, [Validators.required]],
            natureOfBusiness: [model.natureOfBusiness, [Validators.required]],
            countryOfOperation: [model.countryOfOperation, [Validators.required]],
            //--- REGISTERED ADDRESS--//
            registeredComplex: [registeredAddress.street, []],
            registeredPostalCode: [this.addressHolder.registeredAddress, [Validators.required]],
            //--- HEAD OFFICE ADDRESS---//
            officeComplex: [businessAddress.street],
            officePostalCode: [this.addressHolder.businessAddress, []],
            //--- TRADING ADDRESS---//
            tradingComplex: [tradingAddress.street],
            tradingPostalCode: [this.addressHolder.tradingAddress, []],
            //---HELPERS---//
            tradingQuestion: [true],
            headOfficeQuestion: [true],
        });
        //--- pre selected--//
        this.divisionSelected(model.natureOfBusiness);
        this.subdivisionSelected(model.division);
        this.operationSelected(model.subDivision);
        //---end pre selected--//
        this.formGroup.controls['natureOfBusiness'].valueChanges.subscribe((code) => {
            this.divisionSelected(code);
        });
        this.formGroup.controls['division'].valueChanges.subscribe((code) => {
            this.subdivisionSelected(code);
        });
        this.formGroup.controls['subDivision'].valueChanges.subscribe((code) => {
            this.operationSelected(code);
        });
    }


    divisionSelected(code: any): void {
        this.modelOperations = []
        this.modelSubDivisions = [];
        this.modelDivisions = this.store.getState().lookup.natureOfBusiness.find(e => e.code == code)?.divisions || [];
    }

    subdivisionSelected(code: any): void {
        this.modelOperations = [];
        this.modelSubDivisions = (this.modelDivisions || []).find(e => e.code == code)?.majorGroups || [];
    }

    operationSelected(code: any): void {
        this.modelOperations = (this.modelSubDivisions || []).find(e => e.code == code)?.subGroups || [];
    }

    clickNext(): void {
        this.spinner.show();
        let value = this.formGroup.getRawValue();
        this.store.dispatch(BUSINESS_DETAILS({
            ...this.store.getState().businessDetails,
            ...{
                division: value.division,
                sicCode: value.operation,
                operation: value.operation,
                subDivision: value.subDivision,
                tradingName: value.tradingName,
                natureOfBusiness: value.natureOfBusiness,
                countryOfOperation: value.countryOfOperation,
            }
        }));
        this.updateAddresses();
        this.service.sendBusinessDetail('off')
            .subscribe({
                next: () => {
                    this.getFinancialDetails();
                },
                error: (err) => {
                    if (err.status == 200 || err.status == 201) {
                        this.getFinancialDetails();
                    } else {
                        this.spinner.hide();
                        this.router.navigate(['error']).then(doNothing);
                    }
                }
            });
    }

    getFinancialDetails(): void {
        this.service.getFinancialDetail('off')
            .subscribe({
                next: (res) => {
                    this.spinner.hide();
                    this.store.dispatch(FINANCIAL_DETAILS(res));
                    this.store.dispatch(NEXT_STEP());
                },
                error: (err) => {
                    if (err.status == 404) {
                        this.spinner.hide();
                        this.store.dispatch(FINANCIAL_DETAILS({} as FinancialDetail));
                        this.store.dispatch(NEXT_STEP());
                    } else {
                        this.spinner.hide();
                        this.router.navigate(['error']).then(doNothing);
                    }
                }
            })
    }
    get skipSteps(): number {
        let entityType = this.store.getState().clientType;
        if (this.store.getState().relatedParties.length > 0 || FormHelper.isSoleProprietor(entityType)) {
            return 1;
        }
        return 4;
    }
    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP(this.skipSteps));
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }

    getAddress(type: string): Address {
        return this.store.getState().businessDetails
            ?.contactInformation?.addresses.filter((e: Address) => e.type == type).pop() || new Address();
    }

    getPhysicalAddress(): Address {
        return this.store.getState().personalDetails
            ?.contactInformation?.addresses.filter((e: Address) => e.type == this.Physical).pop() || new Address();
    }

    private updateAddresses() {
        const value = this.formGroup.getRawValue();
        let holder = this.store.getState().addressHolder;
        let physicalAddress = this.getPhysicalAddress();
        this.store.dispatch(ADDRESS_DETAIL({
            ...FormHelper.parseAddress(holder.registeredAddress),
            street: value.registeredComplex,
            type: this.RegisteredAddress,
        }));
        if (value.tradingQuestion == true) {
            this.store.dispatch(ADDRESS_DETAIL({
                ...physicalAddress,
                type: this.TradingAddress,
            }));
        } else {
            this.store.dispatch(ADDRESS_DETAIL({
                type: this.TradingAddress,
                street: value.tradingComplex,
                ...FormHelper.parseAddress(holder.tradingAddress),
            }));
        }
        if (value.headOfficeQuestion == true) {
            this.store.dispatch(ADDRESS_DETAIL({
                ...physicalAddress,
                type: this.BusinessAddress,
            }));
        } else {
            this.store.dispatch(ADDRESS_DETAIL({
                type: this.BusinessAddress,
                street: value.officeComplex,
                ...FormHelper.parseAddress(holder.businessAddress),
            }));
        }
    }

    compareOptions(object: any, selected: any): boolean {
        return object != null && selected != null && object == selected;
    }

    onOfficeAddress($event: GoogleAddress) {
        this.store.dispatch(UPDATE_ADDRESS({
            ...this.store.getState().addressHolder,
            businessAddress: $event.formatted_address
        }));
        this.formGroup.patchValue({
            officePostalCode: $event.formatted_address,
        });
    }

    onTradingAddress($event: GoogleAddress) {
        this.store.dispatch(UPDATE_ADDRESS({
            ...this.store.getState().addressHolder,
            tradingAddress: $event.formatted_address
        }));
        this.formGroup.patchValue({
            tradingPostalCode: $event.formatted_address,
        });
    }

    onRegisteredAddress($event: GoogleAddress) {
        this.store.dispatch(UPDATE_ADDRESS({
            ...this.store.getState().addressHolder,
            registeredAddress: $event.formatted_address
        }));
        this.formGroup.patchValue({
            registeredPostalCode: $event.formatted_address,
        });
    }

    get businessAddressValue(): GoogleAddress {
        return {
            formatted_address: this.addressHolder.businessAddress
        } as GoogleAddress;
    }

    get physicalAddressValue(): GoogleAddress {
        return {
            formatted_address: this.addressHolder.physicalAddress
        } as GoogleAddress;
    }

    get tradingAddressValue(): GoogleAddress {
        return {
            formatted_address: this.addressHolder.tradingAddress
        } as GoogleAddress;
    }

    get registeredAddressValue(): GoogleAddress {
        return {
            formatted_address: this.addressHolder.registeredAddress
        } as GoogleAddress;
    }
}