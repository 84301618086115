<form [formGroup]="formGroup">
    <mat-form-field floatLabel="always">
        <mat-label>Address Detail (Street, Suburb, City)</mat-label>
        <input matInput
               maxlength="200"
               [options]='options'
               #placesRef="ngx-places"
               placeholder="Enter your address details"
               ngx-google-places-autocomplete
               formControlName="address_details"
               (onAddressChange)="onAddress($event)"/>
    </mat-form-field>
</form>