<div class="welcome">
    {{ (config | async).applicationWelcome }}
</div>
<div *ngIf="((entity | async).requirementList)">
    <div class="welcome-sub-header">
        In order to complete this application we will require the following:
    </div>
    <ul>
        <li *ngFor="let item of (entity| async).requirementList">
            {{ item.description }}
        </li>
    </ul>
</div>
<br/>
<div class="row navigation-bottom">
    <div class="col-12">
        <button type="button" class="button-active" (click)="clickStart()">Start application</button>
        <br/>
        <br/>
        <button
                type="button"
                *ngIf="showResume"
                class="button-inactive"
                (click)="clickResume()">
            Resume application
        </button>
    </div>
</div>

