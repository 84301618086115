import {Component} from '@angular/core';
import {CoreService} from "@core/services/core.service";

@Component({
    selector: 'app-spinner',
    template: `
        <div class="spinner-container" disabled *ngIf="processing$ | async">
            <mat-spinner></mat-spinner>
        </div>
    `
})

export class SpinnerComponent {

    processing$ = this.spinnerService.loading$;

    constructor(private spinnerService: CoreService,) {
    }
}
