import {ActivatedRoute} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {ErrorMessage} from "@features/services/model/error-message";

@Component({
    selector: 'app-error-view',
    templateUrl: './error-view.component.html',
})
export class ErrorViewComponent implements OnInit {
    message: ErrorMessage;
    messages: ErrorMessage[] = [
        {
            code: 'error',
            header: 'We\'re having a technical issue',
            details: [
                'Sorry for the inconvenience. Please try again later',
            ]
        },
        {
            code: 'questions-failed',
            header: 'Unable to continue',
            details: [
                'Were are not able to verify your details.',
                'Please visit your nearest branch for further assistance with your application'
            ],
        },
        {
            code: 'information-wrong',
            header: 'Unable to continue',
            details: [
                'The information entered is not what we have received from the Department of Home Affairs.',
                'Call the Department of Home Affairs on 0800 601 190 to update your details before applying'
            ],
        }
    ]

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe((params) => {
            const code = params.get('code') || 'error';
            this.message = this.messages.find(e => e.code == code)
                || this.messages.find(e => e.code == 'error');
        });
    }
}
