import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {NgRedux, select} from "@angular-redux/store";
import {FormHelper} from "@features/helper/form-helper";
import {SourceOfFund} from "@features/models/source-of-fund";
import {EmploymentType} from "@features/models/employment-type";
import {OccupationModel} from "@features/models/occupation.model";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {FINANCIAL_DETAILS, NEXT_STEP, PREVIOUS_STEP,} from "@features/state/actions";
import {FormGroup, FormBuilder, Validators, AbstractControl} from '@angular/forms';

@Component({
    selector: 'app-step-six',
    templateUrl: './step-six.component.html'
})
export class StepSixComponent implements OnInit {

    @select((store: ApplicationState) => store.lookup.fundSources) modelSourceOfFund: Observable<SourceOfFund[]>;
    @select((store: ApplicationState) => store.lookup.occupations) modelOccupation: Observable<OccupationModel[]>;
    @select((store: ApplicationState) => store.lookup.fundSources) modelSourceOfIncome: Observable<SourceOfFund[]>;
    @select((store: ApplicationState) => store.lookup.occupationStatuses) modelEmploymentType: Observable<EmploymentType[]>;

    formGroup!: FormGroup;

    constructor(
        private router: Router,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        const model = this.store.getState().financialDetails;
        this.formGroup = this.builder.group({
            occupation: [model.occupation, [Validators.required]],
            sourceOfFunds: [model.sourceOfFunds, [Validators.required]],
            sourceOfIncome: [model.sourceOfIncome, [Validators.required]],
            employmentType: [model.employmentType, [Validators.required]],
            //--TAX---//
            registeredForForeignTax: [false, []],
            foreignTaxNumber: [model.foreignTaxNumber, []],
            //---VAT--//
            registeredForVat: [false, []],
            vatNumber: [model.vatNumber, []],
            //--INCOME TAX
            registeredForIncomeTax: [false, []],
            incomeTaxNumber: [model.incomeTaxNumber, []],
            //-- TOGGLES
            annualCardTurnover: [model.annualCardTurnover, []],
            annualBusinessTurnover: [model.annualBusinessTurnover, []],
            averageCardTransactionValue: [model.averageCardTransactionValue, []],
            averageTicketValueOnDebitCard: [model.averageTicketValueOnDebitCard, []],
            averageTicketValueOnCreditCard: [model.averageTicketValueOnCreditCard, []],
            //-- NOT SURE ABOUT THESE
        });
        let registeredForIncomeTaxControl = this.getControl('registeredForIncomeTax');
        registeredForIncomeTaxControl.valueChanges.subscribe({
            next: (value: boolean) => {
                registeredForIncomeTaxControl.clearValidators();
                if (value == true) {
                    registeredForIncomeTaxControl.setValidators([...FormHelper.TAX_NUMBER_VALIDATION]);
                }
            }
        });

        let registeredForVatControl = this.getControl('registeredForVat');
        registeredForVatControl.valueChanges.subscribe({
            next: (value) => {
                registeredForVatControl.clearValidators();
                if (value == true) {
                    registeredForVatControl.setValidators([...FormHelper.TAX_NUMBER_VALIDATION]);
                }
            }
        });

        let registeredForForeignTaxControl = this.getControl('registeredForForeignTax');
        registeredForForeignTaxControl.valueChanges.subscribe({
            next: (value) => {
                registeredForForeignTaxControl.clearValidators();
                if (value == true) {
                    registeredForForeignTaxControl.setValidators([...FormHelper.TAX_NUMBER_VALIDATION]);
                }
            }
        });
    }

    getControl(name: string): AbstractControl {
        return this.formGroup.controls[name];
    }

    clickNext(): void {
        this.store.dispatch(FINANCIAL_DETAILS({
            ...this.store.getState().financialDetails,
            ...this.formGroup.getRawValue(),
        }));
        this.store.dispatch(NEXT_STEP());
    }


    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP())
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }

    compareOptions(object: any, selected: any): boolean {
        return object != null && selected != null && object == selected;
    }
}
