import {NgRedux, select} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {NEXT_STEP, PERSONAL_DATA, PREVIOUS_STEP, RELATED_PARTY} from "@features/state/actions";
import {ApplicationState} from "@features/state/application-state";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {PersonalDetails} from "@features/models/personal-details";

@Component({
    selector: 'app-related-parties',
    templateUrl: './related-parties.component.html',
})
export class RelatedPartiesComponent implements OnInit {

    formGroup!: FormGroup;
    @select((store: ApplicationState) => store.relatedParties) relatedParties: Observable<PersonalDetails[]>;
    constructor(
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            party: ['', [Validators.required]],
        });
    }

    clickNext(): void {
        let value = this.formGroup.getRawValue();
        let party = this.store.getState().relatedParties.find(e => e.id == value.party);
        this.store.dispatch(RELATED_PARTY(party));
        this.store.dispatch(NEXT_STEP());
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    getDetails(party: PersonalDetails): string {
        if (party.firstName || party.lastName) {
            return `${party.firstName} ${party.lastName}`;
        } else {
            return `${party.idNumber} (${party.roles})`;
        }
    }
}
