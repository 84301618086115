import {Breakpoints} from '@angular/cdk/layout';

export const environment = {
    stepCurrent: 0,
    inMemory: false,
    production: true,
    soleTraderId: 8101,
    registeredPTYId: 2301,
    inMemoryDelay: 2000,
    enablePwaMode: true,
    allowLoginDialog: false,
    enableNgxsDevMode: true,
    allowedIdTypes: ['03', '01'],
    globalHttpErrorSnackbar: true,
    title: 'ABSA Onboarding - Dev',
    globalHttpActivitySpinner: true,
    mobileTitle: 'ABSA Onboarding - Dev',
    globalHttpErrorSnackbarDuration: 5000,
    inferiorBrowsers: ['ie', 'edge', 'safari'],
    product: {
        entityID: 8101,
        name: 'Absa Mobile Pay',
    },
    mobileModeBreakpoint: [
        Breakpoints.Handset,
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Tablet,
        Breakpoints.TabletLandscape,
        Breakpoints.TabletPortrait
    ],
    showSoleTraderOnly: false,
    apiURL: 'service-api',
    docURL: 'documents-api',
    docURL_old: 'https://docuploaduat.absa.co.za/DocHandler/api/Document',
    businessClientAgreement: 'https://www.absa.co.za/content/dam/south-africa/absa/pdf/business/Absa-7231-EX-ENG.pdf',
    merchantAgreement: 'https://www.absa.co.za/content/dam/south-africa/absa/pdf/business/Absa-1391-EX-CPA-Bespoke-Absa-Mobile-Pay-Agreement-ENG.pdf',
    rsaIDNumberPattern: '(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))',
}
