<div [formGroup]="formGroup" style="margin-bottom: 16px !important;">
    <mat-label>{{label}}</mat-label>
    <div *ngIf="labelHint" style="padding-top: 6px !important; padding-left: 6px !important; padding-right: 6px!important;">
        {{labelHint}}
    </div>
    <div style="padding-top: 10px !important;">
        <mat-radio-group formControlName="switchControl">
            <mat-radio-button [value]="true">{{positiveLabel}}</mat-radio-button>
            <br/>
            <br/>
            <mat-radio-button [value]="false">{{negativeLabel}}</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
