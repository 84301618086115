<div class="layout-container">
    <form [formGroup]="formGroup">
        <div class="upload-info">
            <div class="welcome-sub-header">Please make sure documents are:</div>
            <ul>
                <li>Not bigger than 5MB</li>
                <li>PDF, JPG, TIF, or GIF format</li>
                <li>Not password protected</li>
            </ul>
        </div>
        <div class="welcome-sub-header">Required documents</div>
        <div *ngFor="let file of files">
            <app-file-upload [file]="file" (fileUpload)="uploadFile($event)"></app-file-upload>
        </div>
        <div class="navigation-bottom">
            <button type="button" class="button-active" (click)="clickNext()" [disabled]="isInvalid()">Next</button>
            <br/>
            <br/>
        </div>
    </form>
</div>
