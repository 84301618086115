import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {NgRedux} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {doNothing} from "@features/services/helper";
import {environment} from "@environments/environment";
import {CoreService} from "@core/services/core.service";
import {NEXT_STEP, PREVIOUS_STEP} from "@features/state/actions";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
    selector: 'app-step-ten',
    templateUrl: './step-ten.component.html'
})
export class StepTenComponent implements OnInit {

    merchantAgreement: string;
    formGroup: FormGroup;
    businessClientAgreement: string;
    loading$ = this.spinner.loading$;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
        this.merchantAgreement = environment.merchantAgreement;
        this.businessClientAgreement = environment.businessClientAgreement;
    }

    ngOnInit(): void {
        const state = this.store.getState();
        this.formGroup = this.builder.group({
            rbdNumber: [state.ref, [Validators.required]],
            acceptAllOfTheAbove: [false, [Validators.required]],
            productName: [state.product.name, [Validators.required]],
            monthlyFee: [this.getFee(), [Validators.required]],
            tradingName: [state.businessDetails.tradingName, [Validators.required]],
        });
    }

    clickNext(): void {
        this.spinner.show();
        this.uploadDocuments().subscribe({
            next: () => {
                this.submitApplication().subscribe({
                    next: () => {
                        this.spinner.hide()
                        this.store.dispatch(NEXT_STEP());
                    },
                    error: (err) => {
                        this.spinner.hide()
                        if (err.status == 200 || err.status == 201) {
                            this.store.dispatch(NEXT_STEP());
                        } else {
                            this.router.navigate(['error']).then(doNothing);
                        }
                    }
                })
            },
            error: () => {
                this.spinner.hide()
                this.router.navigate(['error']).then(doNothing);
            }
        });
    }

    submitApplication(): Observable<any> {
        return this.service.completeApplication();
    }

    uploadDocuments(): Observable<any> {
        return this.service.sendDocuments(this.store.getState().filesRequired);
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    isValid(): boolean {
        const value = this.formGroup.value;
        const isValid = (value.acceptAllOfTheAbove == true);
        return !isValid;
    }

    private getFee(): string {
        const state = this.store.getState();
        let fee = state.product.monthlyFee || 0;
        return `R ${parseFloat(String(fee)).toFixed(2)}`;
    }

    getAppearance(): any {
        return 'none';
    }
}