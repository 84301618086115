import {Address} from "@features/models/address";
import {EntityType} from "@features/models/entity-type";
import {LookupModel} from "@features/models/lookup.model";
import {AuthRequest} from "@features/models/auth-request";
import {RequiredFile} from "@features/models/required-file";
import {Configuration} from "@features/models/configuration";
import {AddressHolder} from "@features/models/address-holder";
import {QuestionModel} from "@features/models/questions-detail";
import {BusinessDetail} from "@features/models/business-detail";
import {FinancialDetail} from "@features/models/financial-detail";
import {PersonalDetails} from "@features/models/personal-details";
import {ProductAndPricing} from "@features/models/product-and-pricing";

export enum ActionTypes {
    ENTITY,
    LOOKUPS,
    NEXT_STEP,
    CONFIG_DATA,
    UPLOAD_FILE,
    DELETE_FILE,
    RESET_STEPS,
    PERSONAL_DATA,
    RELATED_PARTY,
    PREVIOUS_STEP,
    QUESTION_DATA,
    UPDATE_ADDRESS,
    ADDRESS_DETAIL,
    RELATED_PARTIES,
    PRODUCT_PRICING,
    BUSINESS_DETAILS,
    QUESTION_ANSWERS,
    FINANCIAL_DETAILS,
    RESET_APPLICATION,
    MOVE_RELATED_PARTY,
    DOCUMENTS_REQUIRED,
    AUTHENTICATION_MODEL,
    UPDATE_PERSONAL_DATA,
    UPDATE_ACCOUNT_OPTIONS,
    EVOLVE_DETAILS,
    STEPPER_HEADER,
}

export interface StoreAction {
    type: ActionTypes;
    result?:
        number |
        string |
        Address |
        EntityType |
        LookupModel |
        AuthRequest |
        RequiredFile |
        QuestionModel |
        Configuration |
        AddressHolder |
        RequiredFile[] |
        BusinessDetail |
        PersonalDetails |
        FinancialDetail |
        ProductAndPricing |
        PersonalDetails[] |
        { answerId: number, questionId: number }[] |
        { hasEvolve: boolean, evolveDeposit: boolean }
}

export const APPLICATION_INFORMATION = (result: Configuration): StoreAction => {
    return {
        type: ActionTypes.CONFIG_DATA,
        result: result
    };
}

export const LOOKUPS = (result: LookupModel): StoreAction => {
    return {
        type: ActionTypes.LOOKUPS,
        result: result
    };
}

export const AUTHENTICATION_MODEL = (result: AuthRequest): StoreAction => {
    return {
        type: ActionTypes.AUTHENTICATION_MODEL,
        result: result
    };
}

export const QUESTION_DATA = (result: QuestionModel): StoreAction => {
    return {
        type: ActionTypes.QUESTION_DATA,
        result: result
    };
}

export const RELATED_PARTIES = (result: PersonalDetails[]): StoreAction => {
    return {
        type: ActionTypes.RELATED_PARTIES,
        result: result
    };
}

export const PERSONAL_DATA = (result: PersonalDetails): StoreAction => {
    return {
        type: ActionTypes.PERSONAL_DATA,
        result: result
    };
}
export const RELATED_PARTY = (result: PersonalDetails): StoreAction => {
    return {
        type: ActionTypes.RELATED_PARTY,
        result: result
    };
}

export const MOVE_RELATED_PARTY = (result: PersonalDetails): StoreAction => {
    return {
        type: ActionTypes.MOVE_RELATED_PARTY,
        result: result
    };
}

export const UPDATE_PERSONAL_DATA = (result: PersonalDetails): StoreAction => {
    return {
        type: ActionTypes.UPDATE_PERSONAL_DATA,
        result: result
    };
}

export const QUESTION_ANSWERS = (result: { answerId: number, questionId: number }[]): StoreAction => {
    return {
        type: ActionTypes.QUESTION_ANSWERS,
        result: result
    };
}

export const NEXT_STEP = (steps: number = 1): StoreAction => {
    return {
        type: ActionTypes.NEXT_STEP,
        result: steps,
    }
}

export const DOCUMENTS_REQUIRED = (documents: RequiredFile[]): StoreAction => {
    return {
        type: ActionTypes.DOCUMENTS_REQUIRED,
        result: documents,
    }
}

export const PREVIOUS_STEP = (steps: number = 1): StoreAction => {
    return {
        type: ActionTypes.PREVIOUS_STEP,
        result: steps,
    }
}

export const RESET_APPLICATION = (): StoreAction => {
    return {
        type: ActionTypes.RESET_APPLICATION,
        result: -1
    }
}

export const UPLOAD_FILE = (file: RequiredFile): StoreAction => {
    return {
        type: ActionTypes.UPLOAD_FILE,
        result: file
    }
}

export const DELETE_FILE = (file: RequiredFile): StoreAction => {
    return {
        type: ActionTypes.DELETE_FILE,
        result: file
    }
}

export const BUSINESS_DETAILS = (business: BusinessDetail): StoreAction => {
    return {
        type: ActionTypes.BUSINESS_DETAILS,
        result: business
    }
}

export const FINANCIAL_DETAILS = (financials: FinancialDetail): StoreAction => {
    return {
        type: ActionTypes.FINANCIAL_DETAILS,
        result: financials
    }
}

export const ADDRESS_DETAIL = (result: Address): StoreAction => {
    return {
        type: ActionTypes.ADDRESS_DETAIL,
        result: result
    };
}

export const PRODUCT_PRICING = (result: ProductAndPricing): StoreAction => {
    return {
        type: ActionTypes.PRODUCT_PRICING,
        result: result
    };
}

export const UPDATE_ACCOUNT_OPTIONS = (result: { hasEvolve: boolean, evolveDeposit: boolean }): StoreAction => {
    return {
        type: ActionTypes.UPDATE_ACCOUNT_OPTIONS,
        result: result
    };
}

export const ENTITY = (result: EntityType): StoreAction => {
    return {
        type: ActionTypes.ENTITY,
        result: result
    };
}

export const UPDATE_ADDRESS = (result: AddressHolder): StoreAction => {
    return {
        type: ActionTypes.UPDATE_ADDRESS,
        result: result
    };
}

export const STEPPER_HEADER = (result: string): StoreAction => {
    return {
        type: ActionTypes.STEPPER_HEADER,
        result: result
    };
}

export const EVOLVE_DETAILS = (result: any): StoreAction => {
    return {
        type: ActionTypes.EVOLVE_DETAILS,
        result: result
    };
}

