import {Router} from "@angular/router";
import {NgRedux} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {doNothing} from "@features/services/helper";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {PersonalDetails} from "@features/models/personal-details";
import {ApplicationState} from "@features/state/application-state";
import {QuestionDocument} from "@features/models/questions-detail";
import {FeaturesService} from "@features/services/features.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PagesControllerService} from "@features/services/pages-controller.service";
import {NEXT_STEP, QUESTION_ANSWERS, PERSONAL_DATA, RELATED_PARTIES} from "@features/state/actions";

@Component({
    selector: 'app-customer-questions',
    templateUrl: './customer-questions.component.html',
})
export class CustomerQuestionsComponent implements OnInit {
    formGroup: FormGroup;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
        public controller: PagesControllerService,
    ) {
    }

    ngOnInit(): void {
        if (this.store.getState().questions?.questions) {
            const controlsConfig: any = {};
            this.questionsData.forEach((question) => {
                controlsConfig[`${question.productAuthenticationQuestionID}`] = ['', [Validators.required]]
            });
            this.formGroup = this.builder.group({
                ...controlsConfig
            });
        }
    }


    orderByAnswerID(unsortedData: any[]): { answerID: number, answer: string, isEnteredAnswerYN: boolean }[] {
        return unsortedData.sort((a, b) => a.answerID > b.answerID ? 1 : -1);
    }

    trimQuestion(dirtyData: string) {
        return dirtyData.replace(/\u00a0/g, ' ');
    }

    get questionsData(): QuestionDocument[] {
        return this.store.getState().questions?.questions?.questionDocument
            || [];
    }

    clickQuestion(): void {
        const answers: { answerId: number, questionId: number }[] = [];
        const value = this.formGroup.value;
        for (const key in value) {
            answers.push({answerId: value[key], questionId: parseInt(key)});
        }
        this.store.dispatch(QUESTION_ANSWERS(answers));
        this.spinner.show();
        this.service.sendQuestionsData({
            ref: this.store.getState().ref,
            id: this.store.getState().questions.id,
            questions: {
                questionDocument: this.questionsData
            }
        }, 'off').subscribe({
            next: (res) => {
                if (res.authenticated == true) {
                    this.getRelatedParty();
                } else {
                    this.spinner.hide();
                    this.router.navigate(['error'], {queryParams: {code: 'questions-failed'}}).then(doNothing);
                }
            },
            error: (err) => {
                this.spinner.hide();
                this.router.navigate(['error'], {queryParams: {code: 'questions-failed'}}).then(doNothing);
            }
        });
    }

    getRelatedParty(): void {
        this.service.getRelatedParty('off')
            .subscribe({
                next: (res) => {
                    this.spinner.hide();
                    let idNumber = this.store.getState().authRequest.idNumner;
                    this.store.dispatch(PERSONAL_DATA(res.filter(e => e.idNumber == idNumber).pop()));
                    this.store.dispatch(RELATED_PARTIES(res.filter(e => e.idNumber != idNumber)));
                    this.store.dispatch(NEXT_STEP());
                },
                error: (err) => {
                    if (err.status == 404) {
                        this.spinner.hide();
                        this.store.dispatch(RELATED_PARTIES([]));// NOT A GOOD WAY
                        this.store.dispatch(PERSONAL_DATA({} as PersonalDetails));// NOT A GOOD WAY
                        this.store.dispatch(NEXT_STEP());
                    } else {
                        this.spinner.hide();
                        this.router.navigate(['error'], {queryParams: {code: 'questions-failed'}}).then(doNothing);
                    }
                }
            });
    }

    clickPrevious(): void {
        this.controller.showStart();
    }


    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }
}