import {Observable, Subscription} from "rxjs";
import {NgRedux, select} from "@angular-redux/store";
import {CoreService} from "@core/services/core.service";
import {ApplicationState} from "@features/state/application-state";
import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appInStep]'
})
export class InStepDirective implements OnInit, OnDestroy {
    inStep: number;
    subscriptions: Subscription[] = [];

    @Input() set appInStep(step: number) {
        this.inStep = step;
    }

    processing$ = this.spinner.loading$;
    @select((store: ApplicationState) => store.stepCurrent) currentStep$: Observable<number>;

    constructor(
        private spinner: CoreService,
        private template: TemplateRef<any>,
        private container: ViewContainerRef,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        /**
         *  Not the best approach but can do for now
         */
        this.subscriptions.push(...[
            this.currentStep$.subscribe((step) => {
                this.updateView(step, this.spinner.getValue());
            }),
            this.processing$.subscribe(processing => {
                this.updateView(this.store.getState().stepCurrent, processing);
            })
        ]);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    updateView(step: number, processing: boolean) {
        this.container.clear();
        if ((processing == true || this.inStep != step) == false) {
            this.container.createEmbeddedView(this.template);
        }
    }
}
