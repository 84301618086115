import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-file-details',
    templateUrl: './file-details.component.html'
})
export class FileDetailsComponent {
    @Input() file: File;
    @Input() right = true
    @Input() details: string;
    @Input() icon: 'document' | 'trash' | 'tick';


    constructor() {
    }

    get prefix(): string {
        if (this.icon === 'document') {
            return 'Add';
        }
        return 'Other';
    }

}
