import {HttpHeaders} from "@angular/common/http";

export class Endpoints {
    static PREFIX = '/v1/'
    static ENUMS = Endpoints.PREFIX + 'enums';
    static MERCHANT = Endpoints.PREFIX + 'merchants';
    static DOCUMENTS = Endpoints.PREFIX + 'documents';
    static CONTACT_ME = Endpoints.PREFIX + 'contact-me';
    static PARTIES = Endpoints.PREFIX + 'related-parties';
    static BUSINESS_DETAILS = Endpoints.PREFIX + 'business';
    static CONFIG_PATH = 'assets/json/config/jsonConfig.json';
    static FINANCIAL_DETAILS = Endpoints.PREFIX + 'financial';
    static GET_QUESTIONS = Endpoints.PREFIX + 'xds/questions';
    static SUBMIT_QUESTION = Endpoints.PREFIX + 'xds/answers';
    static SUBMIT_APPLICATION = Endpoints.PREFIX + 'application';
    static COMPANY_CONFIG_PATH = 'assets/json/config/jsonCompanyConfig.json';
    static VERIFY_BUSINESS_DETAILS = Endpoints.PREFIX + 'business-details/verify';
    static VALIDATE_COMPANY_REGISTRATION_NUMBER = Endpoints.PREFIX + 'business-details/validate';
    static AUTH_HEADERS = new HttpHeaders({Accept: 'application/json', Authorization: 'Basic UkJEUA==', rejectUnauthorized:'false'});
}