<form [formGroup]="formGroup">
    <mat-form-field floatLabel="always">
        <mat-label>My income comes from?</mat-label>
        <mat-select
                [compareWith]="compareOptions"
                formControlName="sourceOfIncome"
                placeholder="Select your source of income">
            <mat-option *ngFor="let item of modelSourceOfIncome | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>How i fund my business?</mat-label>
        <mat-select
                [compareWith]="compareOptions"
                formControlName="sourceOfFunds"
                placeholder="Select yours source of funds">
            <mat-option *ngFor="let item of modelSourceOfFund | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>My employment type?</mat-label>
        <mat-select
                [compareWith]="compareOptions"
                formControlName="employmentType"
                placeholder="Select your employment type">
            <mat-option *ngFor="let item of modelEmploymentType | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>My occupation is?</mat-label>
        <mat-select
                formControlName="occupation"
                [compareWith]="compareOptions"
                placeholder="Select your occupation">
            <mat-option *ngFor="let item of modelOccupation | async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <app-switch
            negativeLabel="No"
            positiveLabel="Yes"
            [defaultValue]="false"
            label="Are you registered for income tax in South Africa?"
            [control]="formGroup.controls['registeredForIncomeTax']">
    </app-switch>
    <div *ngIf="formGroup.controls['registeredForIncomeTax'].value===true">
        <mat-form-field floatLabel="always">
            <mat-label>Income tax number</mat-label>
            <input matInput
                   appFieldConstraint
                   [fieldMaxLength]="10"
                   fieldType="ONLY_NUMERIC"
                   [control]="formGroup.controls['incomeTaxNumber']"
                   formControlName="incomeTaxNumber"
                   placeholder="Enter income tax number"/>
        </mat-form-field>
        <mat-error *ngIf="formGroup.controls['incomeTaxNumber'].errors?.['pattern']">
            Income tax number must be valid.
        </mat-error>
    </div>
    <app-switch
            negativeLabel="No"
            positiveLabel="Yes"
            [defaultValue]="false"
            label="Are you registered for VAT in South Africa?"
            [control]="formGroup.controls['registeredForVat']">
    </app-switch>
    <div *ngIf="formGroup.controls['registeredForVat'].value===true">
        <mat-form-field floatLabel="always">
            <mat-label>Income VAT number</mat-label>
            <input matInput
                   maxlength="10"
                   formControlName="vatNumber"
                   appFieldConstraint
                   [fieldMaxLength]="10"
                   fieldType="ONLY_NUMERIC"
                   [control]="formGroup.controls['vatNumber']"
                   placeholder="Enter income VAT number"/>
        </mat-form-field>
        <mat-error *ngIf="formGroup.controls['vatNumber'].errors?.['pattern']">
            VAT number must be valid.
        </mat-error>
    </div>
    <app-switch
            negativeLabel="No"
            positiveLabel="Yes"
            [defaultValue]="false"
            label="Are you registered for foreign tax?"
            [control]="formGroup.controls['registeredForForeignTax']">
    </app-switch>
    <div *ngIf="formGroup.controls['registeredForForeignTax'].value===true">
        <mat-form-field floatLabel="always">
            <mat-label>Foreign tax number</mat-label>
            <input matInput
                   maxlength="10"
                   appFieldConstraint
                   [fieldMaxLength]="10"
                   fieldType="ONLY_NUMERIC"
                   [control]="formGroup.controls['foreignTaxNumber']"
                   formControlName="foreignTaxNumber"
                   placeholder="Enter Foreign tax number"/>
        </mat-form-field>
    </div>
    <div class="navigation-bottom">
        <button type="button"
                class="button-active"
                (click)="clickNext()"
                [disabled]="isInvalid()">
            Next
        </button>
        <br/>
        <br/>
    </div>
</form>