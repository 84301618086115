<form [formGroup]="formGroup">
    <!--
    <mat-form-field>
        <mat-select
                formControlName="cifRole"
                placeholder="Select role which is applicable to you">
            <mat-option *ngFor="let item of cifRoles | async" [value]="item.code">
                {{ item.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    -->
    <div>
        <mat-label>Select role which is applicable to you</mat-label>
        <div style="padding-top: 10px !important;">
            <mat-radio-group formControlName="cifRole">
                <div *ngFor="let item of cifRoles | async">
                    <mat-radio-button [value]="item.code">{{item.description}}</mat-radio-button>
                    <br/><br/>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <div class="navigation-bottom">
        <button
                type="button"
                class="button-active"
                (click)="clickNext()"
                [disabled]="isInvalid()">Next
        </button>
        <br/>
        <br/>
    </div>
</form>
