import {NgControl} from "@angular/forms";
import {Directive, Input, OnInit, Self} from '@angular/core';

@Directive({
    selector: '[appStaticField]'
})
export class StaticFieldDirective implements OnInit {

    @Input() model: any;

    constructor(@Self() private ngControl: NgControl) {
    }

    ngOnInit(): void {
        const controlName = this.ngControl.name;
        if (controlName && this.model &&
            this.model[controlName] && this.ngControl?.control) {
            this.ngControl.control.clearValidators();
            this.ngControl.control.disable();
        }
    }
}
