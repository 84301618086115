import {Router} from "@angular/router";
import {NgRedux} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {Address} from "@features/models/address";
import {doNothing} from "@features/services/helper";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {GoogleAddress} from "@features/models/google-address";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {BusinessDetail, ContactInformation} from "@features/models/business-detail";
import {
    NEXT_STEP,
    BUSINESS_DETAILS,
    UPDATE_PERSONAL_DATA,
    PREVIOUS_STEP, UPDATE_ADDRESS,
} from "@features/state/actions";

@Component({
    selector: 'app-step-four',
    templateUrl: './step-four.component.html'
})
export class StepFourComponent implements OnInit {

    selectedAddress: Address;
    addressType = 'Physical';
    formGroup: FormGroup;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,) {
    }

    ngOnInit(): void {
        const model = this.address;
        let holder = this.store.getState().addressHolder;
        this.formGroup = this.builder.group({
            street: [model.street, []],
            postalCode: [holder.physicalAddress, [Validators.required]],
        });
        if (holder.physicalAddress) {
            this.onAddress({
                formatted_address: holder.physicalAddress,
            } as GoogleAddress);
        }
    }

    get value(): GoogleAddress {
        let holder = this.store.getState().addressHolder;
        return {
            formatted_address: holder.physicalAddress,
        } as GoogleAddress;
    }

    get address(): Address {
        return this.store.getState().personalDetails
            ?.contactInformation?.addresses?.filter((e: Address) => e.type == this.addressType).pop() || {} as Address;
    }

    clickNext(): void {
        let value = this.formGroup.getRawValue();
        let physicalAddress: Address = {
            ...this.address,
            ...this.selectedAddress,
            street: value.street,
            type: this.addressType,
        };
        let auth = this.store.getState().authRequest;
        this.store.dispatch(UPDATE_PERSONAL_DATA({
            ...this.store.getState().personalDetails,
            contactInformation: {
                email: auth.emailAddress,
                cellphone: auth.cellnumber,
                preferredCommunicationMethod: auth.communicationMethod,
                ...this.store.getState().personalDetails?.contactInformation || {} as ContactInformation,
                addresses: [
                    ...this.store.getState().personalDetails?.contactInformation?.addresses || [],
                    physicalAddress,
                ]
            }
        }));
        this.spinner.show();
        let personalDetails = this.store.getState().personalDetails;
        this.service.sendRelatedParty(personalDetails, 'off').subscribe({
            next: () => {
                this.getBusinessDetails();
            },
            error: (err) => {
                if (err.status == 201 || err.status == 200) {
                    this.getBusinessDetails();
                } else {
                    this.spinner.hide();
                    this.router.navigate(['error']).then(doNothing);
                }
            }
        })
    }

    getBusinessDetails(): void {
        this.service.getBusinessDetail('off')
            .subscribe({
                next: (res) => {
                    this.spinner.hide();
                    this.store.dispatch(BUSINESS_DETAILS(res))
                    this.store.dispatch(NEXT_STEP(this.skipSteps));
                },
                error: (err) => {
                    if (err.status == 404) {
                        this.spinner.hide();
                        this.store.dispatch(BUSINESS_DETAILS({} as BusinessDetail))
                        this.store.dispatch(NEXT_STEP(this.skipSteps));
                    } else {
                        this.spinner.hide();
                        this.router.navigate(['error']).then(doNothing);
                    }
                }
            });
    }

    get skipSteps(): number {
        let entityType = this.store.getState().clientType;
        if (this.store.getState().relatedParties.length > 0 || FormHelper.isSoleProprietor(entityType)) {
            return 1;
        }
        return 4;
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }

    onAddress($event: GoogleAddress) {
        this.selectedAddress = FormHelper.parseAddress($event.formatted_address);
        this.store.dispatch(UPDATE_ADDRESS({
            ...this.store.getState().addressHolder,
            physicalAddress: $event.formatted_address
        }));
        this.formGroup.patchValue({
            postalCode: $event.formatted_address,
        });
    }
}