import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html'
})
export class SwitchComponent implements OnInit {
    formGroup: FormGroup;
    @Input() label: string;
    @Input() labelHint: string;
    @Input() positiveLabel: string;
    @Input() negativeLabel: string;
    @Input() control: AbstractControl;
    @Input() defaultValue: boolean | undefined = undefined;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            switchControl: ['', [Validators.required]]
        });
        this.formGroup.controls['switchControl'].valueChanges.subscribe({
            next: (value) => {
                this.control.setValue(value);
            }
        });
        if (this.defaultValue != undefined || this.defaultValue != null) {
            this.formGroup.patchValue({
                switchControl: this.defaultValue
            })
        }
    }

}
