<app-header *ngIf="(processing$ | async)===false"></app-header>
<div class="layout-container">
    <div *ngIf="(processing$ | async)===false">
        <app-step-stepper></app-step-stepper>
    </div>
    <div class="row">
        <div class="col-12">
            <app-spinner></app-spinner>
            <div *ngIf="(processing$ | async)===false">
                <!--
                    SOLE TRADER SCREENS FLOW
                -->
                <div *ngIf="!isCompany(entityType|async)">
                    <app-step-start *appInStep="0"></app-step-start>
                    <app-step-end *appInStep="-1"></app-step-end>
                    <app-step-one *appInStep="1"></app-step-one>
                    <app-step-two *appInStep="2"></app-step-two>
                    <app-step-three *appInStep="3"></app-step-three>
                    <app-step-four *appInStep="4"></app-step-four>
                    <app-step-five *appInStep="5"></app-step-five>
                    <app-step-six *appInStep="6"></app-step-six>
                    <app-step-seven *appInStep="7"></app-step-seven>
                    <app-step-eight *appInStep="8"></app-step-eight>
                    <app-step-nine *appInStep="9"></app-step-nine>
                    <app-step-ten *appInStep="10"></app-step-ten>
                    <app-step-eleven *appInStep="11"></app-step-eleven>
                </div>
                <!--
                    PTY/CC SCREENS FLOW
               -->
                <div *ngIf="isCompany(entityType|async)">
                    <app-step-start *appInStep="0"></app-step-start>
                    <app-step-end *appInStep="-1"></app-step-end>
                    <app-step-one *appInStep="1"></app-step-one>
                    <app-step-two *appInStep="2"></app-step-two>
                    <app-step-three *appInStep="3"></app-step-three>
                    <app-step-four *appInStep="4"></app-step-four>
                    <app-related-parties *appInStep="5"></app-related-parties>
                    <app-parties-details *appInStep="6"></app-parties-details>
                    <app-party-address *appInStep="7"></app-party-address>
                    <app-step-five *appInStep="8"></app-step-five>
                    <app-step-six *appInStep="9"></app-step-six>
                    <app-step-seven *appInStep="10"></app-step-seven>
                    <app-step-eight *appInStep="11"></app-step-eight>
                    <app-step-nine *appInStep="12"></app-step-nine>
                    <app-step-ten *appInStep="13"></app-step-ten>
                    <app-step-eleven *appInStep="14"></app-step-eleven>
                </div>
            </div>
        </div>
    </div>
</div>

