import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {NgRedux, select} from "@angular-redux/store";
import {CifRole} from "@features/models/lookup.model";
import {environment} from "@environments/environment";
import {FormHelper} from "@features/helper/form-helper";
import {UPDATE_PERSONAL_DATA} from "@features/state/actions";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PagesControllerService} from "@features/services/pages-controller.service";

@Component({
    selector: 'app-entity-role',
    templateUrl: './entity-role.component.html'
})
export class EntityRoleComponent implements OnInit {

    merchantAgreement: string;
    formGroup: FormGroup;
    businessClientAgreement: string;
    @Output() roleSelected = new EventEmitter<boolean>();

    @select((store: ApplicationState) => store.lookup.cifRole) cifRoles: Observable<CifRole[]>;

    constructor(
        private router: Router,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
        public controller: PagesControllerService,
    ) {
        this.merchantAgreement = environment.merchantAgreement;
        this.businessClientAgreement = environment.businessClientAgreement;
    }

    ngOnInit(): void {
        let model = this.store.getState().personalDetails;
        this.formGroup = this.builder.group({
            cifRole: [model.roles, [Validators.required]]
        });
    }

    clickNext() {
        let value = this.formGroup.getRawValue();
        let auth = this.store.getState().authRequest;
        let model = this.store.getState().personalDetails;
        this.store.dispatch(UPDATE_PERSONAL_DATA({
            ...model, roles: value.cifRole,
            contactInformation: {
                ...model.contactInformation,
                email: auth.emailAddress,
                cellphone: auth.cellnumber,
                preferredCommunicationMethod: auth.communicationMethod,
            }
        }));
        this.roleSelected.next(true);
    }

    clickPrevious() {
        /*this.controller.showStart();*/
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }
}
