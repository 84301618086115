import {Router} from "@angular/router";
import {NgRedux} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {environment} from "@environments/environment";
import {FormHelper} from "@features/helper/form-helper";
import {AuthRequest} from "@features/models/auth-request";
import {doNothing, moveUp} from "@features/services/helper";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {PagesControllerService} from "@features/services/pages-controller.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
    AUTHENTICATION_MODEL,
    NEXT_STEP,
    PERSONAL_DATA,
    PREVIOUS_STEP,
    QUESTION_DATA,
    RELATED_PARTIES
} from "@features/state/actions";
import {PersonalDetails} from "@features/models/personal-details";
import {CoreService} from "@core/services/core.service";

@Component({
    selector: 'app-customer-authentication',
    templateUrl: './customer-authentication.component.html',
})
export class CustomerAuthenticationComponent implements OnInit {
    formGroup: FormGroup;
    merchantAgreement: string;
    businessClientAgreement: string;
    methodOfCommunication: string = 'email';

    constructor(
        private router: Router,
        private builder: FormBuilder,
        private spinner: CoreService,
        private service: FeaturesService,
        private store: NgRedux<ApplicationState>,
        public controller: PagesControllerService,
    ) {
        this.merchantAgreement = environment.merchantAgreement;
        this.businessClientAgreement = environment.businessClientAgreement;
    }

    ngOnInit(): void {
        let auth = this.store.getState().authRequest;
        this.formGroup = this.builder.group({
            idType: [auth.idType, []],
            idNumner: [auth.idNumner, []],
            agreeBusiness: ['', [Validators.required]],
            agreeMerchant: ['', [Validators.required]],
            contactMethod: [undefined, [Validators.required]],
            //idNumner: [auth.idNumner, [...FormHelper.ID_VALIDATIONS,]],
            cellnumber: [auth.cellnumber, [...FormHelper.MOBILE_NUMBER_VALIDATIONS,]],
            emailAddress: [auth.emailAddress, [Validators.required, Validators.email,]],
        });
        this.getControl('contactMethod')
            .valueChanges.subscribe({
            next: (value: boolean) => {
                this.methodOfCommunication = value == true ? 'email' : 'sms'
            }
        });
    }

    getControl(name: string): AbstractControl {
        return this.formGroup.controls[name];
    }

    clickAuthenticate(): void {
        let value = this.formGroup.getRawValue();
        if(value.idType == '03'){
            this.router.navigate(['contact-me']).then(doNothing);
        }else{
            this.processAuth(value);
        }
    }
    processAuth(value: any):void{
        this.store.dispatch(AUTHENTICATION_MODEL({
            ...this.store.getState().authRequest,
            //idType: '01',
            idType: value.idType,
            isExistingClient: true,
            idNumner: value.idNumner,
            cellnumber: value.cellnumber,
            emailAddress: value.emailAddress,
            communicationMethod: this.methodOfCommunication
        } as AuthRequest));
        this.spinner.show();
        this.service.sendAuthenticationData('off')
            .subscribe({
                next: (res) => {
                    if (value.idType == '03' && res) {
                        this.store.dispatch(QUESTION_DATA(res));
                        this.getRelatedParty();
                    } else {
                        if (res && res.questions
                            && res.questions.questionDocument
                            && res.questions.questionDocument.length > 0) {
                            this.store.dispatch(QUESTION_DATA(res));
                            this.store.dispatch(NEXT_STEP());
                            moveUp();
                        } else {
                            this.router.navigate(['error'], {queryParams: {code: 'questions-failed'}}).then(doNothing);
                        }
                        this.spinner.hide();
                    }
                },
                error: () => {
                    this.router.navigate(['error'], {queryParams: {code: 'questions-failed'}}).then(doNothing);
                    this.spinner.hide();
                }
            });
    }

    clickPrevious(): void {
        this.controller.showStart();// default back to start
        this.store.dispatch(PREVIOUS_STEP());
    }

    notAcceptedTerms(): boolean {
        const value = this.formGroup.getRawValue();
        return (!(value.agreeBusiness == true && value.agreeMerchant == true));
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup) || this.notAcceptedTerms();
    }

    getRelatedParty(): void {
        this.service.getRelatedParty('off')
            .subscribe({
                next: (res) => {
                    let idNumber = this.store.getState().authRequest.idNumner;
                    this.store.dispatch(PERSONAL_DATA(res.filter(e => e.idNumber == idNumber).pop()));
                    this.store.dispatch(RELATED_PARTIES(res.filter(e => e.idNumber != idNumber)));
                    this.store.dispatch(NEXT_STEP(2));
                },
                error: (err) => {
                    if (err.status == 404) {
                        this.store.dispatch(RELATED_PARTIES([]));// NOT A GOOD WAY
                        this.store.dispatch(PERSONAL_DATA({} as PersonalDetails));// NOT A GOOD WAY
                        this.store.dispatch(NEXT_STEP(2));
                    } else {
                        this.router.navigate(['error'], {queryParams: {code: 'questions-failed'}}).then(doNothing);
                    }
                },
                complete: () => this.spinner.hide(),
            });
    }
}