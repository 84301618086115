<form [formGroup]="formGroup">
    <div>
        <mat-label>Please select and confirm all related party details</mat-label>
        <div style="padding-top: 10px !important;">
            <mat-radio-group formControlName="party">
                <div *ngFor="let item of relatedParties | async">
                    <mat-radio-button [value]="item.id">{{getDetails(item)}}</mat-radio-button>
                    <br/><br/>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <div class="navigation-bottom">
        <button
                type="button"
                class="button-active"
                (click)="clickNext()"
                [disabled]="formGroup.invalid">
            Next
        </button>
        <br/>
        <br/>
    </div>
</form>