import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {doNothing} from "@features/services/helper";
import {FormHelper} from "@features/helper/form-helper";
import {FeaturesService} from "@features/services/features.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-contact-me',
    templateUrl: './contact-me.component.html',
})
export class ContactMeComponent extends BehaviorSubject<boolean> implements OnInit {

    toggle$ = this.asObservable();
    formGroup: FormGroup;
    times: string[] = [
        'Morning (08:00 - 12:00)',
        'Afternoon (12:00 - 15:00)',
        'Late afternoon (15:00 - 17:00)',
    ];

    constructor(
        private router: Router,
        private service: FeaturesService,
        private builder: FormBuilder,
    ) {
        super(false);
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            firstName: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            mobileNumber: ['', [Validators.required]],
            contactTime: ['', [Validators.required]],
        });
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }

    submitContactForm() {
        this.service.submitContactMe({
            ...this.formGroup.getRawValue(),
        }).subscribe({
            next: () => {
                this.router.navigate(['success']).then(doNothing);
            },
            error: () => {
                this.router.navigate(['error']).then(doNothing);
            }
        });
    }

    contactMe() {
        this.next(true);
    }

    cancel() {
        this.router.navigate(['success']).then(doNothing);
    }
}
