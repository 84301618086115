import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PagesControllerService} from "@features/services/pages-controller.service";

@Component({
    selector: 'app-resume-application',
    templateUrl: './resume-application.component.html'
})
export class ResumeApplicationComponent implements OnInit {

    formGroup: FormGroup;

    constructor(
        private builder: FormBuilder,
        public controller: PagesControllerService,
    ) {

    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            rdbnumber: ['', [
                Validators.required,
            ]],
        });
    }

    clickPrevious(): void {
        this.controller.showStart();
    }

    resumeApplication(): void {
    }
}