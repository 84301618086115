import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {Country} from "@features/models/country";
import {NgRedux, select} from "@angular-redux/store";
import {FormHelper} from "@features/helper/form-helper";
import {Nationality} from "@features/models/nationality";
import {CoreService} from "@core/services/core.service";
import {MaritualStatus} from "@features/models/maritual-status";
import {Designation, Gender} from "@features/models/lookup.model";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MOVE_RELATED_PARTY, NEXT_STEP, PREVIOUS_STEP} from "@features/state/actions";

@Component({
    selector: 'app-parties-details',
    templateUrl: './parties-details.component.html'
})
export class PartiesDetailsComponent implements OnInit {

    model: any;
    formGroup: FormGroup;

    @select((store: ApplicationState) => store.lookup.genders) modelGender: Observable<Gender[]>;
    @select((store: ApplicationState) => store.lookup.designations) designations: Observable<Designation[]>;
    @select((store: ApplicationState) => store.lookup.nationalities) modelNationality: Observable<Nationality[]>;
    @select((store: ApplicationState) => store.lookup.countriesOfOrigin) modelCountryOfBirth: Observable<Country[]>;
    @select((store: ApplicationState) => store.lookup.maritalStatuses) modelMaritualStatus: Observable<MaritualStatus[]>;
    @select((store: ApplicationState) => store.lookup.countriesOfResidence) modelCountryOfResidence: Observable<Country[]>;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
        this.model = this.store.getState().currentRelatedParty;
    }

    ngOnInit(): void {
        let model = this.store.getState().currentRelatedParty;
        this.formGroup = this.builder.group({
            //idType: [model.idType, []],
            roles: [model.roles, [Validators.required]],
            gender: [model.gender, [Validators.required]],
            lastName: [model.lastName, [Validators.required]],
            firstName: [model.firstName, [Validators.required]],
            nationality: [model.nationality, [Validators.required]],
            idNumber: [model.idNumber, [...FormHelper.ID_VALIDATIONS]],
            maritalStatus: [model.maritalStatus, [Validators.required]],
            countryOfBirth: [model.countryOfBirth, [Validators.required]],
            sharePercentage: [model.sharePercentage, [Validators.required]],
            countryOfResidence: [model.countryOfResidence, [Validators.required]],
            email: [model?.contactInformation?.email, [Validators.required, Validators.email]],
            cellphone: [model?.contactInformation?.cellphone, [...FormHelper.MOBILE_NUMBER_VALIDATIONS]]
        });
        /*this.formGroup.controls['idType'].valueChanges.subscribe({
            next: (value) => {
                if (value == '03') {
                    this.formGroup.addControl('passportDetails',
                        this.builder.control(null, [Validators.required]));
                } else {
                    this.formGroup.removeControl('passportDetails')
                }
            }
        });*/
    }

    clickNext(): void {
        let value = this.formGroup.getRawValue();
        let model = this.store.getState().currentRelatedParty;
        this.store.dispatch(MOVE_RELATED_PARTY({
            ...model, ...value,
            contactInformation: {
                ...model.contactInformation,
                email: value.email,
                cellphone: value.cellphone
            }
        }));
        this.store.dispatch(NEXT_STEP());
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    compareOptions(object: any, selected: any): boolean {
        return object != null && selected != null && object == selected;
    }

    fieldHasValue(key: string): boolean {
        return !!this.model[key];
    }

    getAppearance(key: string): any {
        return this.fieldHasValue(key) ? 'none' : 'standard';
    }

    /*isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }*/
    isInvalid(): boolean {
        return Object.keys(this.formGroup.controls).filter(key => this.formGroup.controls[key].invalid).length > 0;
    }

    getControl(name: string): AbstractControl {
        return this.formGroup.controls[name];
    }
}
