<form [formGroup]="formGroup">
    <mat-form-field floatLabel="always">
        <mat-label>Date of birth</mat-label>
        <input matInput
               [disabled]="true"
               [matDatepicker]="dateOfBirth"
               formControlName="dateOfBirth">
        <mat-datepicker-toggle
                matSuffix [for]="dateOfBirth">
        </mat-datepicker-toggle>
        <mat-datepicker
            #dateOfBirth
            startView="year"
            disabled="false">
        </mat-datepicker>
    </mat-form-field>
    <app-validation-error
            message="Valid date of birth is required"
            [controller]="formGroup.controls['dateOfBirth']">
    </app-validation-error>
    <mat-form-field floatLabel="always">
        <mat-label>Date of issue</mat-label>
        <input matInput
               [disabled]="true"
               [matDatepicker]="dateOfIssue"
               formControlName="dateOfIssue">
        <mat-datepicker-toggle
                matSuffix [for]="dateOfIssue">
        </mat-datepicker-toggle>
        <mat-datepicker
            #dateOfIssue
            startView="year"
            disabled="false">
        </mat-datepicker>
    </mat-form-field>
    <app-validation-error
            message="Valid date of birth is required"
            [controller]="formGroup.controls['dateOfBirth']">
    </app-validation-error>
    <mat-form-field floatLabel="always">
        <mat-label>Expiry date</mat-label>
        <input matInput
               [disabled]="true"
               [matDatepicker]="expiryDate"
               formControlName="expiryDate">
        <mat-datepicker-toggle
                matSuffix [for]="expiryDate">
        </mat-datepicker-toggle>
        <mat-datepicker
            #expiryDate
            startView="year"
            [startAt]="currentDate"
            disabled="false">
        </mat-datepicker>
    </mat-form-field>
    <app-validation-error
            message="Expiry date is required"
            [controller]="formGroup.controls['expiryDate']">
    </app-validation-error>
    <mat-form-field floatLabel="always">
        <mat-label>Passport country of issue</mat-label>
        <mat-select
                placeholder="Select your country of passport issue"
                formControlName="countryOfIssue">
            <mat-option *ngFor="let item of countries |async" [value]="item.code">
                {{ item.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
