<div class="stepper-container" *ngIf="((stepCurrent | async) > 0) && ((stepCurrent| async) <= stepCount)">
    <div class="row m-3 pt-3">
        <div class="stepper-title" *ngIf="(stepTitle | async)">
           Ref number:  {{ stepTitle | async }}
        </div>
        <div class="stepper-description">
            {{ stepDescription | async }}
        </div>
    </div>
    <div class="row m-3">
        <div *ngFor="let step of stepArray | async;let i = index;" class="col p-0">
            <div [ngClass]="{'step-active' : i <= ((stepCurrent | async)-1), 'step-inactive' : i > ((stepCurrent|async)-1)}">
                &nbsp;
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 step-text">
            Step {{ stepCurrent | async }} of {{ stepCount  }}
        </div>
    </div>
</div>