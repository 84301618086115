import {FormBuilder, FormGroup} from "@angular/forms";
import {GoogleAddress} from "@features/models/google-address";
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-address-picker',
    templateUrl: './address-picker.component.html'
})
export class AddressPickerComponent implements OnInit {

    options: any = {
        componentRestrictions: {country: "za"},
    };
    formGroup: FormGroup;
    @Input() value: GoogleAddress;
    @Output() address = new EventEmitter<GoogleAddress>();
    @ViewChild("placesRef") placesRef: GooglePlaceDirective;

    constructor(
        private builder: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            address_details: [this.value?.formatted_address]
        });
    }

    onAddress($event: Address) {
        this.address.emit({
            ...$event,
        });
    }
}
