import {Component} from '@angular/core';
import {PagesControllerService} from "@features/services/pages-controller.service";

@Component({
    selector: 'app-step-start',
    templateUrl: './step-start.component.html'
})
export class StepStartComponent {
    constructor(
        public controller: PagesControllerService,
    ) {
    }
}
