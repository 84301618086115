<form [formGroup]="formGroup">
    <app-switch
            [defaultValue]="true"
            negativeLabel="Passport"
            label="Pick your identification"
            positiveLabel="South African ID"
            [control]="formGroup.controls['idType']">
    </app-switch>
    <div *ngIf="selectedIdType===true">
        <mat-form-field floatLabel="always">
            <mat-label>South African ID number</mat-label>
            <input matInput
                   maxlength="13"
                   appFieldConstraint
                   [fieldMaxLength]="13"
                   fieldType="ONLY_NUMERIC"
                   formControlName="idNumber"
                   [control]="formGroup.controls['idNumber']"
                   placeholder="Enter your South African ID number"/>
        </mat-form-field>
        <app-validation-error
                [controller]="formGroup.controls['idNumber']"
                [message]="'A valid ID number is required.'">
        </app-validation-error>
    </div>
    <div *ngIf="selectedIdType===false">
        <mat-form-field floatLabel="always">
            <mat-label>Passport number</mat-label>
            <input matInput
                   maxlength="13"
                   appFieldConstraint
                   [fieldMaxLength]="13"
                   fieldType="ALPHA_NUMERIC"
                   formControlName="passportNumber"
                   placeholder="Enter your Passport number"
                   [control]="formGroup.controls['passportNumber']"/>
        </mat-form-field>
        <app-validation-error
                [controller]="formGroup.controls['passportNumber']"
                [message]="'A valid Passport number is required.'">
        </app-validation-error>
    </div>
</form>
