import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppComponent} from '../app.component';
import {Title} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpClientModule} from '@angular/common/http';
import {CoreService} from '@core/services/core.service';
import {CoreRoutingModule} from '@core/core-routing.module';
import {MaterialModule} from '@core/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorComponent} from '@core/components/error/error.component';
import {ErrorViewComponent} from '@core/views/error/error-view.component';
import {ServiceInterceptorInterceptor} from "@core/interceptors/service-interceptor.interceptor";
import { AccountCardComponent } from './account-card/account-card.component';
import { AccountDetailsComponent } from './account-details/account-details.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MaterialModule,
        HttpClientModule,
        CoreRoutingModule,
        ReactiveFormsModule,
    ],
    declarations: [
        AppComponent,
        ErrorComponent,
        ErrorViewComponent,
        AccountCardComponent,
        AccountDetailsComponent,
    ],
    providers: [
        Title,
        CoreService,
        {
            multi: true,
            provide: HTTP_INTERCEPTORS,
            useClass: ServiceInterceptorInterceptor
        }
    ],
    exports: [
        ErrorViewComponent,
        AccountCardComponent,
        AccountDetailsComponent
    ]
})
export class CoreModule {
}
