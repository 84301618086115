import {Component, Input, OnInit} from '@angular/core';
import {FormHelper} from "@features/helper/form-helper";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-id-details',
    templateUrl: './id-details.component.html'
})
export class IdDetailsComponent implements OnInit {

    formGroup: FormGroup;
    selectedIdType: boolean;
    @Input() idType: AbstractControl;
    @Input() idValue: AbstractControl;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            idType: ['', [Validators.required]]
        });
        this.formGroup.controls['idType'].valueChanges.subscribe({
            next: (value) => {
                this.selectedIdType = value;
                this.idValue.clearValidators();
                this.idType.setValue(value ? '01' : '03');
                this.formGroup.removeControl('idNumber');
                this.formGroup.removeControl('passportNumber');
                this.idValue.setValidators(value ? FormHelper.ID_VALIDATIONS : FormHelper.PASSPORT_VALIDATIONS);
                this.formGroup.addControl(value ? 'idNumber' : 'passportNumber', this.idValue);
            }
        });
    }
}
