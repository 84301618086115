<form [formGroup]="formGroup">
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>Fee per terminal rental</mat-label>
        <input matInput
               readonly="true"
               placeholder="Fee per terminal rental"
               formControlName="terminalRentalFee"/>
        <i class="fa fa-info-circle fa-2x information-icon" aria-hidden="true"></i>
    </mat-form-field>
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>Master/Visa debit card</mat-label>
        <input matInput
               readonly="true"
               placeholder="Master/Visa debit card"
               formControlName="masterVisaDebitCardPercentage"/>
        <i class="fa fa-info-circle fa-2x information-icon" aria-hidden="true"></i>
    </mat-form-field>
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>Master/Visa credit card</mat-label>
        <input matInput
               readonly="true"
               placeholder="Master/Visa credit card"
               formControlName="masterVisaCreditCardPercentage"/>
        <i class="fa fa-info-circle fa-2x information-icon" aria-hidden="true"></i>
    </mat-form-field>
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>International cards</mat-label>
        <input matInput
               readonly="true"
               placeholder="International cards"
               formControlName="internationalCards"/>
        <i class="fa fa-info-circle fa-2x information-icon" aria-hidden="true"></i>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Merchant class</mat-label>
        <mat-select
                placeholder="Select your merchant class"
                [compareWith]="compareOptions"
                formControlName="merchantClass">
            <mat-option *ngFor="let item of modelMerchantClass | async" [value]="item.description">
                {{ item.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="navigation-bottom">
        <button type="button" class="button-active" (click)="clickNext()" [disabled]="isInvalid()">Next</button>
        <br/>
        <br/>
    </div>
</form>