import {NgRedux} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {RequiredFile} from "@features/models/required-file";
import {ApplicationState} from "@features/state/application-state";
import {DOCUMENTS_REQUIRED, NEXT_STEP, PREVIOUS_STEP, UPLOAD_FILE} from "@features/state/actions";

@Component({
    selector: 'app-upload-debug',
    templateUrl: './upload-debug.component.html'
})
export class UploadDebugComponent implements OnInit {

    files: RequiredFile[] = [];
    formGroup: FormGroup;

    constructor(
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {

        this.store.dispatch(DOCUMENTS_REQUIRED([
            {id: 1, description: 'Test 1', category: 'TEST FILE'} as RequiredFile,
            {id: 2, description: 'Test 2', category: 'TEST FILE'} as RequiredFile,
            {id: 3, description: 'Test 3', category: 'TEST FILE'} as RequiredFile,
        ]));
    }

    ngOnInit(): void {
        console.log('---COMPONENT INIT---');
        this.files = this.store.getState().filesRequired;
        this.formGroup = this.builder.group({});
    }


    uploadFile(file: RequiredFile): void {
        console.log('---FILE UPLOADED---');
        this.store.dispatch(UPLOAD_FILE(file));
    }

    clickNext(): void {
        this.store.dispatch(NEXT_STEP());
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    isInvalid(): boolean {
        let files = this.store.getState()
            .filesRequired.filter((e: RequiredFile) => e.file == undefined || e.file == null).map(e => e.id);
        return (files.length > 0);
    }

}
