import {Observable} from "rxjs";
import {Component, OnInit} from '@angular/core';
import {NgRedux, select} from "@angular-redux/store";
import {FormGroup, FormBuilder} from '@angular/forms';
import {RequiredFile} from "@features/models/required-file";
import {ApplicationState} from "@features/state/application-state";
import {DELETE_FILE, NEXT_STEP, PREVIOUS_STEP, UPLOAD_FILE} from "@features/state/actions";
import {FormHelper} from "@features/helper/form-helper";

@Component({
    selector: 'app-step-nine',
    templateUrl: './step-nine.component.html'
})
export class StepNineComponent implements OnInit {

    formGroup: FormGroup;
    @select((store: ApplicationState) => store.filesRequired) files: Observable<RequiredFile[]>;

    constructor(
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({});
    }


    onFileUpload(file: RequiredFile): void {
        this.store.dispatch(UPLOAD_FILE(file));
    }

    onFileDelete(file: RequiredFile): void {
        this.store.dispatch(DELETE_FILE(file));
    }

    clickNext(): void {
        this.store.dispatch(NEXT_STEP());
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    isInvalid(): boolean {
        let files = this.store.getState()
            .filesRequired.filter((e: RequiredFile) => e.file == undefined || false).map(e => e.id);
        return (files.length > 0);
    }

    get ids(): any[] {
        let state = this.store.getState();
        return [
            ...(FormHelper.isSoleProprietor(state.clientType) ? [] : ['company']),
            state.personalDetails.idNumber,
            ...state.moveRelatedParties.map(e => e.idNumber),
        ];
    }

    requiredFile(id: string): RequiredFile[] {
        let state = this.store.getState();
        if (id == 'company') {
            return state.filesRequired.filter(e => this.ids.indexOf(e.description) < 0);
        } else {
            return state.filesRequired.filter(e => e.description == id);
        }
    }

    fileDescription(id: string): string {
        let state = this.store.getState();
        if (id == 'company') {
            return state.businessDetails?.tradingName;
        } else {
            return [
                ...state.moveRelatedParties,
                state.personalDetails
            ]
                .filter(e => e.idNumber == id)
                .map(e => `${e.firstName} ${e.lastName}`)
                .pop();
        }
    }
}
