<div style="padding-bottom: 16px!important;">
    <mat-grid-list cols="6" (click)="selected()">
        <mat-grid-tile colspan="5" rowspan="1" style="align-content: flex-start!important;">
            <div style="align-content: flex-start!important;">
                <div class="general-header">{{plan.name}}</div>
                <div>{{plan.price_plan}} per month. {{plan.annual_turnover}}</div>
            </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
            <mat-icon svgIcon="wizzit_more_arrow" style="color:grey!important;"></mat-icon>
        </mat-grid-tile>
    </mat-grid-list>
</div>