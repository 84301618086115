import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Component} from '@angular/core';
import {NEXT_STEP} from "@features/state/actions";
import {NgRedux, select} from "@angular-redux/store";
import {EntityType} from "@features/models/entity-type";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {doNothing, moveUp} from "@features/services/helper";
import {Configuration} from "@features/models/configuration";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {PagesControllerService} from "@features/services/pages-controller.service";

@Component({
    selector: 'app-start-application',
    templateUrl: './start-application.component.html',
})
export class StartApplicationComponent {

    showResume = false;
    @select((store: ApplicationState) => store.clientType) entity: Observable<EntityType>;
    @select((store: ApplicationState) => store.config) config: Observable<Configuration>;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private store: NgRedux<ApplicationState>,
        public controller: PagesControllerService,
    ) {
    }

    clickStart(): void {
        /*if (FormHelper.isSoleProprietor(this.store.getState().clientType)) {
            this.spinner.show();
            this.store.dispatch(NEXT_STEP());
            this.spinner.hide();
        } else {
            let registrationNumber = this.store.getState().authRequest?.registrationNumber;
            this.service.verifyBusinessDetails(registrationNumber)
                .subscribe({
                    next: () => {
                        this.store.dispatch(NEXT_STEP());
                    },
                    error: (err) => {
                        if (err.status == 200) {
                            this.store.dispatch(NEXT_STEP());
                        } else {
                            this.router.navigate(['contact-me']).then(doNothing);
                        }
                    }
                });
        }*/
        this.store.dispatch(NEXT_STEP());
        moveUp();
    }

    clickResume() {
        this.controller.showResume();
    }
}