<form [formGroup]="formGroup" class="stepper-container">
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>Product</mat-label>
        <input matInput
               readonly="true"
               placeholder="Product"
               formControlName="product"/>
    </mat-form-field>
    <mat-form-field floatLabel="always">
        <mat-label>Business entity type</mat-label>
        <mat-select
                formControlName="entityType"
                placeholder="Choose your business type">
            <mat-option *ngFor="let item of entities | async" [value]="item.id">
                {{ item.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <app-switch
            negativeLabel="No"
            positiveLabel="Yes"
            *ngIf="registrationNumber$ | async"
            [control]="formGroup.controls['authorisedToOpenAccount']"
            label="Are you authorised to open this account on behalf of your company">
    </app-switch>
    <mat-form-field floatLabel="always" *ngIf="registrationNumber$ | async">
        <mat-label>Company registration number</mat-label>
        <input matInput
               appStaticField
               appFieldConstraint
               [fieldMaxLength]="12"
               fieldType="ONLY_NUMERIC"
               [control]="formGroup.controls['registrationNumber']"
               formControlName="registrationNumber"
               placeholder="Enter your company registration number"/>
    </mat-form-field>
    <div class="navigation-bottom">
        <button type="button"
                class="button-active"
                (click)="clickNext()"
                [disabled]="isInvalid()">Next
        </button>
        <br/>
        <br/>
    </div>
</form>