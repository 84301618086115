import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Component} from '@angular/core';
import {doNothing} from "@features/services/helper";
import {NgRedux, select} from "@angular-redux/store";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {PREVIOUS_STEP, RESET_APPLICATION} from "@features/state/actions";

@Component({
    selector: 'app-step-end',
    templateUrl: './step-end.component.html'
})
export class StepEndComponent {

    @select((store: ApplicationState) => store.ref) ref: Observable<string>;

    constructor(
        private router: Router,
        private service: FeaturesService,
        private store: NgRedux<ApplicationState>
    ) {
    }

    clickNext(): void {
        this.store.dispatch(RESET_APPLICATION());
        this.router.navigate(['success']).then(doNothing);
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }
}
