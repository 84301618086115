<form [formGroup]="formGroup">
    <!--
    <mat-form-field floatLabel="always">
        <mat-label>South African ID number</mat-label>
        <input matInput
               maxlength="13"
               formControlName="idNumner"
               appFieldConstraint
               [fieldMaxLength]="13"
               fieldType="ONLY_NUMERIC"
               [control]="formGroup.controls['idNumner']"
               placeholder="Enter your South African ID number"/>
    </mat-form-field>
    <app-validation-error
            [controller]="getControl('idNumner')"
            [message]="'A valid ID number is required.'">
    </app-validation-error>
    -->
    <app-id-details
            [idType]="formGroup.controls['idType']"
            [idValue]="formGroup.controls['idNumner']">
    </app-id-details>
    <mat-form-field floatLabel="always">
        <mat-label>Cellphone number</mat-label>
        <input matInput
               maxlength="14"
               mask="(000) 000-0000"
               placeholder="e.g. 074 555 5432"
               formControlName="cellnumber"
               appFieldConstraint
               [fieldMaxLength]="14"
               fieldType="ONLY_NUMERIC"
               [control]="formGroup.controls['cellnumber']"/>
    </mat-form-field>
    <app-validation-error
            [controller]="getControl('cellnumber')"
            [message]="'A valid cellphone number is required.'">
    </app-validation-error>
    <mat-form-field floatLabel="always">
        <mat-label>Email Address</mat-label>
        <input
                formControlName="emailAddress"
                matInput placeholder="Enter your email address"/>
    </mat-form-field>
    <app-validation-error
            [controller]="getControl('emailAddress')"
            [message]="'Please enter a valid email address'">
    </app-validation-error>
    <app-switch
            negativeLabel="Phone"
            positiveLabel="Email"
            label="How do we keep in touch?"
            [control]="formGroup.controls['contactMethod']">
    </app-switch>
    <div class="padded-checkbox">
        <mat-checkbox formControlName="agreeBusiness">I understand and agree to the
        </mat-checkbox>
    </div>
    <div class="padded-link">
        <a [href]="businessClientAgreement" target="_blank">Business Client Agreement</a>
    </div>
    <div class="padded-checkbox">
        <mat-checkbox formControlName="agreeMerchant">I understand and agree to the
        </mat-checkbox>
    </div>
    <div class="padded-link">
        <a [href]="merchantAgreement" target="_blank">Merchant Agreement</a>
    </div>
    <p>
        We will use your personal data only to provide you with the service or product that you are applying
        for.
    </p>
    <mat-divider></mat-divider>
    <br/>
    <p>
        Please read this privacy notice in conjunction with our detailed privacy statement, which may be
        found at
        absa.co. za
    </p>
    <!-- TERMS -->
    <div class="navigation-bottom">
        <button type="button"
                class="button-active"
                (click)="clickAuthenticate()"
                [disabled]="isInvalid()">
            Next
        </button>
        <br/>
        <br/>
    </div>
</form>
