import {Observable} from "rxjs";
import {Component} from '@angular/core';
import {select} from "@angular-redux/store";
import {moveUp} from "@features/services/helper";
import {Product} from "@features/models/lookup.model";
import {Configuration} from "@features/models/configuration";
import {ApplicationState} from "@features/state/application-state";
import {PagesControllerService} from "@features/services/pages-controller.service";

@Component({
    selector: 'app-apply',
    templateUrl: './apply.component.html'
})
export class ApplyComponent {


    @select((store: ApplicationState) => store.product) product: Observable<Product>;
    @select((store: ApplicationState) => store.config) config: Observable<Configuration>;

    constructor(
        public controller: PagesControllerService,
    ) {
    }

    clickApply(): void {
        this.controller.showEntity();
        moveUp();
    }
}
