<form [formGroup]="formGroup">
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>My reference number</mat-label>
        <input matInput
               readonly="true"
               formControlName="rbdNumber"
               placeholder="My reference number"/>
    </mat-form-field>
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>Product</mat-label>
        <input matInput
               placeholder="Product"
               readonly="true"
               formControlName="productName"/>
    </mat-form-field>
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>Trading name</mat-label>
        <input matInput
               readonly="true"
               placeholder="Trading name"
               formControlName="tradingName"/>
    </mat-form-field>
    <mat-form-field [appearance]="getAppearance()">
        <mat-label>Monthly fee</mat-label>
        <input matInput
               readonly="true"
               placeholder="Monthly fee"
               formControlName="monthlyFee"/>
    </mat-form-field>
    <div class="welcome-sub-header">
        Please acknowledge that you agree to the following terms and conditions:
    </div>
    <br/>
    <ul>
        <li>
            I give Absa permission to use my details to perform a credit assessment and verification
        </li>
        <li>
            The information I've given is true and correct
        </li>
        <li>
            I understand and agree to the <a [href]="merchantAgreement" target="_blank">Merchant Agreement</a>
        </li>
        <li>
            I acknowledge and agree to the <a [href]="businessClientAgreement" target="_blank">Business Client
            Agreement</a>
        </li>
    </ul>
    <div class="padded-checkbox">
        <mat-checkbox formControlName="acceptAllOfTheAbove">I accept all of the above</mat-checkbox>
    </div>
    <div class="navigation-bottom">
        <button type="button"
                class="button-active"
                (click)="clickNext()"
                [disabled]="isValid() || (loading$ | async)">Submit
        </button>
    </div>
</form>