<form [formGroup]="formGroup">
    <!--
        START FORM
    -->
    <mat-form-field>
        <input matInput
               placeholder="First name"
               formControlName="firstName"/>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               placeholder="Surname"
               formControlName="lastName"/>
    </mat-form-field>
    <!--
    <mat-form-field>
        <input matInput
               placeholder="Type of identification"
               formControlName="idType"/>
    </mat-form-field>
    -->
    <mat-form-field>
        <input matInput
               placeholder="South African ID Number"
               formControlName="idNumber"/>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               placeholder="Country of birth"
               formControlName="countryOfBirth"/>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               placeholder="Gender"
               formControlName="gender"/>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               placeholder="Marital status"
               formControlName="maritalStatus"/>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               placeholder="Nationality"
               formControlName="nationality"/>
    </mat-form-field>
    <!--
        END OF FORM
    -->
    <div class="navigation-bottom">
        <button type="button" class="button-inactive" (click)="clickPrevious()">
            This information is incorrect
        </button>
        <br/>
        <br/>
        <button type="button" class="button-active" (click)="clickNext()">Next</button>
    </div>
</form>
