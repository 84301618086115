import {Observable} from "rxjs";
import {NgRedux, select} from "@angular-redux/store";
import {Component, ChangeDetectionStrategy} from '@angular/core';
import {StepArrayModel} from "@features/models/step-array.model";
import {ApplicationState} from "@features/state/application-state";

@Component({
    selector: 'app-step-stepper',
    templateUrl: './step-stepper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepStepperComponent {

    @select((store: ApplicationState) => store?.ref) stepTitle: Observable<string>;
    @select((store: ApplicationState) => store?.stepCurrent) stepCurrent: Observable<number>;
    @select((store: ApplicationState) => store.clientType?.name) stepDescription: Observable<string>;
    @select((store: ApplicationState) => store.config?.stepArray) stepArray: Observable<StepArrayModel[]>;

    constructor(
        private store: NgRedux<ApplicationState>,
    ) {
    }
    get stepCount() {
        return this.store.getState().config.stepArray.length;
    }
}
