<form [formGroup]="formGroup">
    <div *ngFor="let question of questionsData; let i = index">
        {{trimQuestion(question.question)}}
        <mat-form-field [floatLabel]="'never'">
            <mat-select [formControlName]="question.productAuthenticationQuestionID" placeholder="Please select...">
                <mat-option
                        *ngFor="let option of orderByAnswerID(question.answers.answerDocument)"
                        [value]="option.answerID">
                    {{ option.answer }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="navigation-bottom">
        <button type="button" class="button-active"
                (click)="clickQuestion()"
                [disabled]="isInvalid()">
            Next
        </button>
        <br/>
    </div>
</form>

