import {Observable, map, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {NgRedux} from "@angular-redux/store";
import {Endpoints} from "@features/services/endpoints";
import {CoreService} from "@core/services/core.service";
import {LookupModel, Product} from "@features/models/lookup.model";
import {RequiredFile} from "@features/models/required-file";
import {Configuration} from "@features/models/configuration";
import {QuestionModel} from "@features/models/questions-detail";
import {BusinessDetail} from "@features/models/business-detail";
import {PersonalDetails} from "@features/models/personal-details";
import {FinancialDetail} from "@features/models/financial-detail";
import {ApplicationState} from "@features/state/application-state";
import {ProductAndPricing} from "@features/models/product-and-pricing";
import {AuthenticationResponse} from "@features/models/authentication-response";
import {FormHelper} from "@features/helper/form-helper";

@Injectable()
export class FeaturesService {

    constructor(
        private service: CoreService,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    loadApplicationConfig(configPath: string): Observable<Configuration> {
        return this.service.getResource<Configuration>(configPath)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getLookupInformation(spinner: string = 'on'): Observable<LookupModel> {
        return this.service.get<LookupModel>(Endpoints.ENUMS, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );

    }

    sendAuthenticationData(spinner: string = 'on'): Observable<QuestionModel> {
        let product = this.state.product;
        let request = this.state.authRequest;
        let clientType = this.state.clientType;
        return this.service.post<QuestionModel>(Endpoints.GET_QUESTIONS, {
            ...request,
            //product,
            products: [product],
            clientType,
        }, spinner)
            .pipe(
                map(questions => {
                    return questions;
                })
            );
    }

    sendQuestionsData(model: QuestionModel, spinner: string = 'on'): Observable<AuthenticationResponse> {
        /*if (!FormHelper.isSoleProprietor(this.state.clientType)) {
            return of({authenticated: true} as AuthenticationResponse);
        } else {*/
        return this.service.post<AuthenticationResponse>(Endpoints.SUBMIT_QUESTION,
            {
                ...model,
            }, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
        //}
    }

    getRelatedParty(spinner: string = 'on'): Observable<PersonalDetails[]> {
        return this.service.get<PersonalDetails[]>(`${Endpoints.PARTIES}/${this.reference}`, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    sendRelatedParty(relatedParty: PersonalDetails, spinner: string = 'on'): Observable<any> {
        return this.service.post<any>(`${Endpoints.PARTIES}/${this.reference}`,
            {
                ...relatedParty,
            }, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );

    }

    getBusinessDetail(spinner: string = 'on'): Observable<BusinessDetail> {
        return this.service.get<BusinessDetail>(`${Endpoints.BUSINESS_DETAILS}/${this.reference}`, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    sendBusinessDetail(spinner: string = 'on'): Observable<any> {
        let auth = this.state.authRequest;
        let request = this.state.businessDetails;
        return this.service.post<any>(`${Endpoints.BUSINESS_DETAILS}/${this.reference}`,
            {
                ...request,
                registrationNumber: auth.registrationNumber,
                contactInformation: {
                    ...request.contactInformation,
                    email: auth.emailAddress,
                    cellphone: auth.cellnumber,
                    preferredCommunicationMethod: auth.communicationMethod,
                }
            }, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getFinancialDetail(spinner: string = 'on'): Observable<FinancialDetail> {
        return this.service.get<FinancialDetail>(`${Endpoints.FINANCIAL_DETAILS}/${this.reference}`, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    sendFinancialDetail(model: FinancialDetail | any, spinner: string = 'on'): Observable<any> {
        return this.service.post<any>(`${Endpoints.FINANCIAL_DETAILS}/${this.reference}`,
            {
                ...model
            }, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getMerchantDetail(spinner: string = 'on'): Observable<ProductAndPricing> {
        return this.service.get<ProductAndPricing>(`${Endpoints.MERCHANT}/${this.reference}`, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    sendMerchantDetail(model: ProductAndPricing, spinner: string = 'on'): Observable<any> {
        return this.service.post<any>(`${Endpoints.MERCHANT}/${this.reference}`,
            {
                ...model,
            }, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    getDocuments(spinner: string = 'on'): Observable<RequiredFile[]> {
        return this.service.get<RequiredFile[]>(`${Endpoints.DOCUMENTS}/${this.reference}`, spinner)
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    sendDocuments(documents: Array<RequiredFile>): Observable<any> {
        return new Observable((observer) => {
            const requests: Array<Promise<any>> = [];
            const headers = {
                spinner: 'off',
                headers: Endpoints.AUTH_HEADERS,

            };
            if (documents) {
                documents.map((document) => {
                    if (document.file) {
                        const formData = new FormData();
                        formData.append('docId', document.docId);
                        formData.append('files[]', document.file);
                        formData.append('caseId', document.caseId);
                        formData.append('category', document.description);
                        formData.append('docSubType', document.docSubType);
                        formData.append('description', document.description);
                        requests.push(
                            this.service.uploadDocument(`/`, formData, headers)
                        );
                    }
                });
                Promise.all(requests)
                    .then((result) => {
                        return observer.next(result);
                    }).catch((err) => {
                    return observer.error(err);
                });
            }
        });
    }

    completeApplication(): Observable<any> {
        return this.service.post<any>(`${Endpoints.SUBMIT_APPLICATION}/${this.reference}`,
            {reference: this.reference}, 'off')
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    private get reference(): string {
        return this.state?.ref;
    }

    submitContactMe(body: any): Observable<any> {
        return this.service.post<any>(Endpoints.CONTACT_ME,
            {...body})
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    verifyBusinessDetails(registrationNumber: string) {
        return this.service.post<any>(Endpoints.VERIFY_BUSINESS_DETAILS,
            {registrationNumber: registrationNumber})
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    validateCompanyRegistrationNumber(registrationNumber: string) {
        return this.service.post<any>(Endpoints.VALIDATE_COMPANY_REGISTRATION_NUMBER,
            {registrationNumber})
            .pipe(
                map(result => {
                    return result;
                })
            );
    }

    get state(): ApplicationState {
        return this.store.getState();
    }

    get mockProduct(): Product {
        return {
            associatedEntityTypes: null,
            section: "BUSINESS_EVOLVE",
            url: "https://www.absa.co.za/business/bank-my-business/",
            type: "A Business Account",
            schemaCode: "",
            color: "8px solid #f52d28",
            businessEvolvePricePlans: [],
            description: "",
            code: "11021",
            name: "A Business Account"
        } as Product;
    }
}
