import {Address} from "@features/models/address";
import {environment} from "@environments/environment";
import {EntityType} from "@features/models/entity-type";
import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

export class FormHelper {

    static isInvalid(form: FormGroup) {
        return !form.valid /*|| ( !form.dirty && form.touched)*/;
    }

    static ID_VALIDATIONS: any[] = [
        Validators.required,
        Validators.minLength(13),
        Validators.maxLength(13),
        Validators.pattern(environment.rsaIDNumberPattern),
    ];

    static PASSPORT_VALIDATIONS: any[] = [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(13)
    ];

    static ACCOUNT_VALIDATIONS: any[] = [
        Validators.required,
        Validators.pattern('[0-9]+'),
        Validators.minLength(7),
        Validators.maxLength(11),
    ]

    static MOBILE_NUMBER_VALIDATIONS: any[] = [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('[0-9]+'),
    ]

    static TAX_NUMBER_VALIDATION: any[] = [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(10),
        //Validators.pattern('/^([4][0-9]{9})$/'),
    ]
    static PTY_COMPANY_REGISTRATION_NUMBER_VALIDATION: any[] = [
        Validators.required,
        FormHelper.ptyCompanyRegistrationNumberValidator()
    ]

    static CC_COMPANY_REGISTRATION_NUMBER_VALIDATION: any[] = [
        Validators.required,
        FormHelper.ccCompanyRegistrationNumberValidator()
    ]

    static parseAddress(formatted_address: string): Address {
        let parts = formatted_address.split(',');
        return {
            postalCode: (parts?.pop() || '').trim(),
            city: (parts?.pop() || '').trim(),
            suburb: (parts?.pop() || '').trim(),
            street2: (parts?.pop() || '').trim(),
            country: 'ZA'
        } as Address;
    }

    static isSoleProprietor(entityType: EntityType): boolean {
        return (environment.soleTraderId == entityType.id);
    }

    static isRegisteredPTY(entityType: EntityType): boolean {
        return (environment.registeredPTYId == entityType.id);
    }

    static isMock(): boolean {
        return environment.showSoleTraderOnly;
    }

    static ptyCompanyRegistrationNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value || value.length < 12 || (value.length == 12 && /07$/.test(value))) {
                return null;
            }
            return {companyRegistrationNumber: true};
        }
    }

    static ccCompanyRegistrationNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value || value.length < 12 || (value.length == 12 && /23$/.test(value))) {
                return null;
            }
            return {companyRegistrationNumber: true};
        }
    }
}