import {Injectable} from '@angular/core';
import {finalize, Observable} from 'rxjs';
import {CoreService} from "@core/services/core.service";
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';

@Injectable()
export class ServiceInterceptorInterceptor implements HttpInterceptor {
    segments = [
        'assets'
    ];

    constructor(private service: CoreService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const spinner = request.headers.get('spinner') || 'off';
        const skippedSegments = this.segments.filter(e => request.url.indexOf(e) >= 0).pop();
        if (request.url.indexOf('jsonConfig.json') < 0 && (skippedSegments || spinner == 'off')) {
            return next.handle(request);
        }else{
            this.service.show();
            return next.handle(request).pipe(
                finalize(() => {
                    this.service.hide();
                })
            );
        }
    }
}
