<form [formGroup]="formGroup">
    <div class="row navigation-bottom">
        <div class="col-12">
            <mat-form-field>
                <input matInput placeholder="Please enter your RBD number" formControlName="rdbnumber"/>
            </mat-form-field>
        </div>
    </div>
</form>
<div class="row navigation-bottom">
    <div class="col-12">
        <button type="button" class="button-active" (click)="resumeApplication()">Start application</button>
    </div>
</div>

