import {Router} from "@angular/router";
import {NgRedux} from "@angular-redux/store";
import {Component, OnInit} from '@angular/core';
import {Address} from "@features/models/address";
import {doNothing} from "@features/services/helper";
import {CoreService} from "@core/services/core.service";
import {FormHelper} from "@features/helper/form-helper";
import {GoogleAddress} from "@features/models/google-address";
import {PersonalDetails} from "@features/models/personal-details";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NEXT_STEP, PREVIOUS_STEP, UPDATE_ADDRESS} from "@features/state/actions";

@Component({
    selector: 'app-party-address',
    templateUrl: './party-address.component.html'
})
export class PartyAddressComponent implements OnInit {
    selectedAddress: Address;
    formGroup!: FormGroup;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            street: ['', []],
            postalCode: ['', [Validators.required]],
        });
    }

    clickPrevious(): void {
        this.store.dispatch(PREVIOUS_STEP());
    }

    onAddress($event: GoogleAddress) {
        this.selectedAddress = FormHelper.parseAddress($event.formatted_address);
        this.store.dispatch(UPDATE_ADDRESS({
            ...this.store.getState().addressHolder,
            physicalAddress: $event.formatted_address
        }));
        this.formGroup.patchValue({
            postalCode: $event.formatted_address,
        });
    }

    clickNext(): void {
        let value = this.formGroup.getRawValue();
        let physicalAddress: Address = {
            ...this.selectedAddress,
            street: value.street,
            type: 'Physical',
        };
        let party = this.store.getState().currentRelatedParty;
        let request = {
            ...party,
            contactInformation: {
                ...party.contactInformation,
                addresses: [
                    ...party.contactInformation?.addresses || [],
                    physicalAddress,
                ]
            }
        } as PersonalDetails;
        this.spinner.show();
        this.service.sendRelatedParty(request, 'off').subscribe({
            next: () => {
                this.spinner.hide();
                this.moveNext();
            },
            error: (err) => {
                this.spinner.hide();
                if (err.status == 201 || err.status == 200) {
                    this.moveNext();
                } else {
                    this.spinner.hide();
                    this.router.navigate(['error']).then(doNothing);
                }
            }
        });
    }

    moveNext(): void {
        if (this.store.getState().relatedParties.length > 0) {
            this.store.dispatch(NEXT_STEP(-2));
        } else {
            this.store.dispatch(NEXT_STEP());
        }
    }
}
