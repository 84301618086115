import {NgModule} from '@angular/core';
import {NgxMaskModule} from 'ngx-mask';
import {CoreModule} from '@core/core.module';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {FeaturesModule} from '@features/features.module';
import {rootReducer} from "@features/state/root-reducer";
import {NgRedux, NgReduxModule} from "@angular-redux/store";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApplicationState, INIT_STATE} from "@features/state/application-state";

@NgModule({
    providers: [],
    declarations: [],
    imports: [
        CoreModule,
        BrowserModule,
        NgReduxModule,
        FeaturesModule,
        AppRoutingModule,
        GooglePlaceModule,
        BrowserAnimationsModule,
        NgxMaskModule.forRoot(),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private store: NgRedux<ApplicationState>) {
        this.store.configureStore(rootReducer, INIT_STATE, []);
    }
}
