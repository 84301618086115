import {Address} from "@features/models/address";
import {environment} from "@environments/environment";
import {EntityType} from "@features/models/entity-type";
import {AuthRequest} from "@features/models/auth-request";
import {RequiredFile} from "@features/models/required-file";
import {Configuration} from "@features/models/configuration";
import {AddressHolder} from "@features/models/address-holder";
import {BusinessDetail} from "@features/models/business-detail";
import {ActionTypes, StoreAction} from "@features/state/actions";
import {PersonalDetails} from "@features/models/personal-details";
import {FinancialDetail} from "@features/models/financial-detail";
import {ProductAndPricing} from "@features/models/product-and-pricing";
import {IdType, LookupModel, Product} from "@features/models/lookup.model";
import {ApplicationState, INIT_STATE} from "@features/state/application-state";
import {QuestionDocument, QuestionModel} from "@features/models/questions-detail";

function comparator(a: any, b: any): number {
    let NAME = 'SOUTH AFRICA';
    if (a.label.toUpperCase().indexOf(NAME) > -1) {
        return -1
    } else if (b.label.toUpperCase().indexOf(NAME) > -1) {
        return 1;
    }
    return 0;
}

export function rootReducer(state: ApplicationState, action: StoreAction): ApplicationState {
    const SA_CODE = ['ZAF', 'ZA', 'YES'];
    const stepArray = state?.config?.stepArray || [];
    switch (action.type) {
        case ActionTypes.LOOKUPS:
            let lookup = action.result as LookupModel;
            let modelIdType = lookup.idTypes.filter((e: IdType) => environment.allowedIdTypes.indexOf(e.code) > -1).pop();
            let modelProduct = lookup.products.find((e: Product) => e.name.toLowerCase().toString() == environment.product.name.toLowerCase().toString());

            return {
                ...state,
                idType: modelIdType.code,
                lookup: {
                    ...lookup,
                    nationalities: lookup.nationalities.sort(comparator),
                    countriesOfOrigin: lookup.countriesOfOrigin.sort(comparator),
                    countriesOfResidence: lookup.countriesOfResidence.sort(comparator),
                    entityTypes: lookup.entityTypes.filter(e => e.name != 'Partnership'),
                },
                product: modelProduct
            };
        case ActionTypes.CONFIG_DATA:
            return {
                ...state,
                config: action.result as Configuration,
                stepData: stepArray.find(search => search.id == 0),
            };
        case ActionTypes.QUESTION_DATA:
            let questions = action.result as QuestionModel;
            return {
                ...state,
                questions,
                ref: questions.ref,
            };
        case ActionTypes.AUTHENTICATION_MODEL:
            return {
                ...state,
                authRequest: {...action.result as AuthRequest},
            };
        case ActionTypes.QUESTION_ANSWERS:
            let answers = action.result as { answerId: number, questionId: number }[];
            let question = state.questions.questions.questionDocument;
            let answeredQuestions: QuestionDocument[] = [];
            question.forEach(question => {
                let answer = answers.find(e => e.questionId == question.productAuthenticationQuestionID);
                let questionAnswers = question.answers.answerDocument;
                answeredQuestions.push({
                    ...question,
                    answers: {
                        ...question.answers,
                        answerDocument: [
                            ...questionAnswers.filter(e => e.answerID != answer.answerId),
                            ...[{
                                ...questionAnswers.find(e => e.answerID == answer.answerId),
                                isEnteredAnswerYN: true,
                            }]
                        ]
                    }
                });
            });
            return {
                ...state,
                questions: {
                    ...state.questions,
                    questions: {
                        ...state.questions.questions,
                        questionDocument: answeredQuestions,
                    }
                }
            };
        case ActionTypes.RESET_STEPS:
            return {
                ...state,
                stepData: stepArray.find(search => search.id == 0),
                stepCurrent: -1
            };
        case ActionTypes.NEXT_STEP:
            let nextSize = action.result as number;
            let nextStep = (state.stepCurrent + nextSize) > stepArray.length ? -1 : (state.stepCurrent + nextSize);
            return {
                ...state,
                stepCurrent: nextStep,
                stepData: stepArray.find(search => search.id == nextStep),
            };
        case ActionTypes.PREVIOUS_STEP:
            let prevSize = action.result as number;
            let prevStep = (state.stepCurrent - prevSize) < 0 ? 0 : (state.stepCurrent - prevSize);
            return {
                ...state,
                stepCurrent: prevStep,
                stepData: stepArray.find(search => search.id == prevStep),
            };
        case ActionTypes.RELATED_PARTIES:
            let parties = action.result as PersonalDetails[]
            return {
                ...state,
                relatedParties: [
                    ...parties.map((e) => {
                        return {
                            ...e,
                            nationality: SA_CODE.indexOf(e.nationality) > -1 ? 'SOU01' : e.nationality,
                            countryOfBirth: SA_CODE.indexOf(e.countryOfBirth) > -1 ? 'SO003' : e.countryOfBirth,
                            countryOfResidence: SA_CODE.indexOf(e.countryOfResidence) > -1 ? 'SO003' : e.countryOfResidence,
                        };
                    })
                ],
            };
        case ActionTypes.UPDATE_PERSONAL_DATA:
            let person = action.result as PersonalDetails;
            let personRole = person.roles || 'Director';
            return {
                ...state,
                personalDetails: {...person, roles: personRole},
            };
        case ActionTypes.PERSONAL_DATA:
            let personalDetail = action.result as PersonalDetails
            let roles = personalDetail.roles || 'Director';
            let nationality = SA_CODE.indexOf(personalDetail.nationality) > -1 ? 'SOU01' : personalDetail.nationality; // override country of birth till the mappings are provided
            let countryOfBirth = SA_CODE.indexOf(personalDetail.countryOfBirth) > -1 ? 'SO003' : personalDetail.countryOfBirth; // override country of birth till the mappings are provided
            let countryOfResidence = SA_CODE.indexOf(personalDetail.countryOfResidence) > -1 ? 'SO003' : personalDetail.countryOfResidence; // override country of birth till the mappings are provided
            return {
                ...state,
                copyPersonalDetails: {
                    ...personalDetail,
                    idType: '01',
                    nationality: nationality,
                    countryOfBirth: countryOfBirth,
                    countryOfResidence: countryOfResidence,
                },
                personalDetails: {
                    ...personalDetail,
                    idType: '01',
                    roles: roles,
                    nationality: nationality,
                    countryOfBirth: countryOfBirth,
                    countryOfResidence: countryOfResidence,
                },
            };
        case ActionTypes.DOCUMENTS_REQUIRED :
            return {
                ...state,
                filesRequired: action.result as RequiredFile[],
            };
        case ActionTypes.FINANCIAL_DETAILS :
            return {
                ...state,
                financialDetails: action.result as FinancialDetail,
            };
        case ActionTypes.RESET_APPLICATION :
            return INIT_STATE;
        case ActionTypes.UPLOAD_FILE :
            let file = action.result as RequiredFile;
            let filesRequired = [...state.filesRequired];
            const index = filesRequired.findIndex((e: RequiredFile) => e.id === file.id);
            filesRequired[index] = file;
            return {
                ...state,
                filesRequired,
            };
        case ActionTypes.DELETE_FILE :
            let deletedFile = action.result as RequiredFile;
            let files = [...state.filesRequired];
            const deletedIndex = files.findIndex((e: RequiredFile) => e.id === deletedFile.id);
            files[deletedIndex] = deletedFile;
            return {
                ...state,
                filesRequired: files,
            };
        case ActionTypes.ADDRESS_DETAIL :
            let address = action.result as Address;
            let addresses = state.businessDetails?.contactInformation?.addresses || [];
            return {
                ...state,
                businessDetails: {
                    ...state.businessDetails,
                    contactInformation: {
                        ...state.businessDetails.contactInformation,
                        addresses: [
                            ...addresses.filter(e => e.type != address.type),
                            address,
                        ]
                    }
                },
            };
        case  ActionTypes.BUSINESS_DETAILS :
            return {
                ...state,
                businessDetails: action.result as BusinessDetail,
            };
        case ActionTypes.PRODUCT_PRICING :
            return {
                ...state,
                productPricing: action.result as ProductAndPricing,
            };
        case ActionTypes.UPDATE_ACCOUNT_OPTIONS :
            let value = action.result as { hasEvolve: boolean, evolveDeposit: boolean };
            return {
                ...state,
                hasEvolve: value.hasEvolve,
                evolveDeposit: value.evolveDeposit,
            };
        case ActionTypes.ENTITY :
            let entity = action.result as EntityType;
            return {
                ...state,
                clientType: entity
            };
        case ActionTypes.UPDATE_ADDRESS :
            let addressHolder = action.result as AddressHolder;
            return {
                ...state,
                addressHolder: {...addressHolder},
            };
        case ActionTypes.RELATED_PARTY :
            let currentParty = action.result as PersonalDetails;
            return {
                ...state,
                currentRelatedParty: currentParty,
            };
        case ActionTypes.MOVE_RELATED_PARTY :
            let moveParty = action.result as PersonalDetails;
            return {
                ...state,
                currentRelatedParty: {...moveParty},
                moveRelatedParties: [...state.moveRelatedParties, moveParty],
                relatedParties: [...state.relatedParties.filter(e => e.id != moveParty.id)],
            };
        case ActionTypes.EVOLVE_DETAILS:
            let evolve = action.result as any;
            return {
                ...state,
                evolveDetails: {...state.evolveDetails, ...evolve}
            };
        case ActionTypes.STEPPER_HEADER:
            let header = action.result as string;
            return {
                ...state,
                stepData: {
                    ...state.stepData,
                    headerText: header
                }
            };
        default:
    }
    return state;
}