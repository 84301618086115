import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OnboardingViewComponent} from '@features/views/onboarding/onboarding-view.component';

const routes: Routes = [
    {
        path: '',
        component: OnboardingViewComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
