import {RequiredFile} from "@features/models/required-file";
import {Component, Input, Output, ChangeDetectionStrategy, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit {
    MX_FILE = 5;
    MB = 1024 * 1024;
    isTooBig: boolean = false;
    @Input() file: RequiredFile;
    fileUploading: boolean = false;
    @Output() fileUpload = new EventEmitter<RequiredFile>();
    @Output() fileDelete = new EventEmitter<RequiredFile>();
    fileDescription: string = '+ Select file / Take photo to upload...';

    constructor() {
    }

    ngOnInit(): void {
        if (this.file.file) {
            this.fileDescription = 'File uploaded.';
        }
    }

    compileFile(selectedFile: any): any {
        this.fileUploading = true;
        this.isTooBig = false;
        const fileData = selectedFile.target.files[0];
        if (fileData) {
            if (fileData.size / this.MB > this.MX_FILE) {
                this.isTooBig = true;
            } else {
                this.file = {...this.file, file: fileData};
                this.fileUpload.emit({...this.file, file: fileData} as RequiredFile);
            }
        }
    }

    delete(): void {
        this.file = {...this.file, file: null};
        if (this.fileDelete && this.file.file) {
            this.fileDelete.emit({...this.file, file: null} as RequiredFile)
        }
    }

    get description(): string {
        if (this.file.file) {
            return `${this.file.category} (${this.file.file.name})`;
        } else {
            return `${this.file.category} (${this.file.description})`;
        }
    }
}
