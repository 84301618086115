<div *ngIf="!right">
    <img src="assets/icons/google/{{icon}}.svg" style="padding-right: 14px!important;">{{prefix}} {{details}}
</div>
<div *ngIf="right" style="width: 100%!important;">
    <div style="float: left; width: 50%!important;  text-align: start!important;" *ngIf="file">
        {{details}}
        <div style="font-weight: 400; color: rgba(45, 34, 34, 0.5); font-size: 14px;">
            {{file.size / 1000000 | number:'1.0-2'}}Mb
        </div>
    </div>
    <div style="float: left;width: 50%!important; text-align: end!important; margin-top: 2px!important;">
        <img src="assets/icons/google/{{icon}}.svg">
    </div>
</div>