import {NgModule} from '@angular/core';
import {NgxMaskModule} from "ngx-mask";
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@core/material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {SuccessComponent} from './views/success/success.component';
import {FeaturesService} from '@features/services/features.service';
import {HeaderComponent} from './components/header/header.component';
import {InStepDirective} from "@features/directives/in-step.directive";
import {FeaturesRoutingModule} from '@features/features-routing.module';
import {StaticFieldDirective} from './directives/static-field.directive';
import {SpinnerComponent} from '@core/components/spinner/spinner.component';
import {ContactMeComponent} from './views/contact-me/contact-me.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {OnboardingViewComponent} from './views/onboarding/onboarding-view.component';
import {FieldConstraintDirective} from "@core/directives/field-constraint.directive";
import {StepStepperComponent} from './components/step-stepper/step-stepper.component';
import {RelatedPartyComponent} from './components/related-party/related-party.component';
import {StepEndComponent} from "@features/components/step-array/step-end/step-end.component";
import {StepOneComponent} from "@features/components/step-array/step-one/step-one.component";
import {StepSixComponent} from "@features/components/step-array/step-six/step-six.component";
import {StepTwoComponent} from "@features/components/step-array/step-two/step-two.component";
import {StepTenComponent} from "@features/components/step-array/step-ten/step-ten.component";
import {AddressPickerComponent} from './components/address-picker/address-picker.component';
import {StepFourComponent} from "@features/components/step-array/step-four/step-four.component";
import {StepFiveComponent} from "@features/components/step-array/step-five/step-five.component";
import {StepNineComponent} from "@features/components/step-array/step-nine/step-nine.component";
import {StepElevenComponent} from './components/step-array/step-eleven/step-eleven.component';
import {StepEightComponent} from "@features/components/step-array/step-eight/step-eight.component";
import {StepThreeComponent} from "@features/components/step-array/step-three/step-three.component";
import {StepSevenComponent} from "@features/components/step-array/step-seven/step-seven.component";
import {StepStartComponent} from "@features/components/step-array/step-start/step-start.component";
import {ApplyComponent} from './components/step-array/step-start/components/apply/apply.component';
import {EntityComponent} from './components/step-array/step-start/components/entity/entity.component';
import {EntityRoleComponent} from './components/step-array/step-start/components/entity-role/entity-role.component';
import {EntityPartyComponent} from './components/step-array/step-start/components/entity-party/entity-party.component';
import {
    PartyDetailsComponent
} from './components/step-array/step-start/components/party-details/party-details.component';
import {
    StartApplicationComponent
} from './components/step-array/step-start/components/start-application/start-application.component';
import {
    ResumeApplicationComponent
} from './components/step-array/step-start/components/resume-application/resume-application.component';
import {
    CustomerQuestionsComponent
} from './components/step-array/step-start/components/customer-questions/customer-questions.component';
import {
    CustomerAuthenticationComponent
} from './components/step-array/step-start/components/customer-authentication/customer-authentication.component';
import { SwitchComponent } from './components/switch/switch.component';
import { FileDetailsComponent } from './components/file-details/file-details.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { RelatedPartiesComponent } from './components/step-array/related-parties/related-parties.component';
import { PartyAddressComponent } from './components/step-array/party-address/party-address.component';
import { PartiesDetailsComponent } from './components/step-array/parties-details/parties-details.component';
import { UploadDebugComponent } from './views/upload-debug/upload-debug.component';
import { IdDetailsComponent } from './components/id-details/id-details.component';
import { SharesDetailsComponent } from './components/shares-details/shares-details.component';
import { PassportDetailsComponent } from './components/passport-details/passport-details.component';
import {CoreModule} from "@core/core.module";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        NgxMaskModule,
        MaterialModule,
        GooglePlaceModule,
        ReactiveFormsModule,
        FeaturesRoutingModule,
        CoreModule,
    ],
    declarations: [
        ApplyComponent,
        InStepDirective,
        EntityComponent,
        HeaderComponent,
        StepEndComponent,
        SpinnerComponent,
        StepOneComponent,
        StepSixComponent,
        StepTenComponent,
        SuccessComponent,
        StepTwoComponent,
        StepFourComponent,
        StepFiveComponent,
        StepNineComponent,
        StepThreeComponent,
        ContactMeComponent,
        StepSevenComponent,
        StepEightComponent,
        StepStartComponent,
        EntityRoleComponent,
        StepElevenComponent,
        FileUploadComponent,
        EntityPartyComponent,
        StepStepperComponent,
        StaticFieldDirective,
        RelatedPartyComponent,
        PartyDetailsComponent,
        AddressPickerComponent,
        OnboardingViewComponent,
        FieldConstraintDirective,
        StartApplicationComponent,
        ResumeApplicationComponent,
        CustomerQuestionsComponent,
        CustomerAuthenticationComponent,
        SwitchComponent,
        FileDetailsComponent,
        ValidationErrorComponent,
        RelatedPartiesComponent,
        PartyAddressComponent,
        PartiesDetailsComponent,
        UploadDebugComponent,
        IdDetailsComponent,
        SharesDetailsComponent,
        PassportDetailsComponent,
    ],
    providers: [
        FeaturesService
    ],
})
export class FeaturesModule {
}
