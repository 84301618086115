<form [formGroup]="formGroup">
    <div class="upload-info">
        <div class="welcome-sub-header">Please make sure documents are:</div>
        <ul>
            <li>Not bigger than 5MB</li>
            <li>PDF, JPG, TIF, or GIF format</li>
            <li>Not password protected</li>
        </ul>
    </div>
    <div class="welcome-sub-header">Required documents</div>
    <div *ngFor="let id of ids" class="upload-container">
        <div class="welcome-sub-header-entry">{{fileDescription(id)}}</div>
        <div *ngFor="let file of requiredFile(id)">
            <app-file-upload
                    [file]="file"
                    (fileUpload)="onFileUpload($event)"
                    (fileDelete)="onFileDelete($event)">
            </app-file-upload>
        </div>
    </div>
    <div class="navigation-bottom">
        <button type="button" class="button-active" (click)="clickNext()" [disabled]="isInvalid()">Next</button>
        <br/>
        <br/>
    </div>
</form>