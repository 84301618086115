import {Component, Input} from '@angular/core';
import {BusinessEvolvePricePlan, BusinessEvolveValueAddedService} from "@features/models/lookup.model";

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html'
})
export class AccountDetailsComponent {

  @Input() plan: BusinessEvolvePricePlan;
  @Input() terms: BusinessEvolveValueAddedService[];

  constructor() { }

}
