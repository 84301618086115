import {AbstractControl} from "@angular/forms";
import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appFieldConstraint]'
})
export class FieldConstraintDirective {

  @Input() control: AbstractControl;
  @Input() fieldMaxLength: number = 35;
  @Input() fieldType:
      'ALPHA' |
      'EMAIL' |
      'ONLY_NUMERIC' |
      'ALPHA_NUMERIC' |
      'SPACE_ALPHA_NUMERIC'
      = 'ALPHA';

  @HostListener('keyup', ['$event'])
  onAction(e: KeyboardEvent) {
    let value = [...this.controlValue];
    switch (this.fieldType) {
      case "ONLY_NUMERIC":
        this.control.setValue(value.filter(key => FieldConstraintDirective.onlyNumbers(key)).join(''));
        break;
      case "ALPHA_NUMERIC":
        this.control.setValue(value.filter(key => FieldConstraintDirective.alphaNumeric(key)).join(''));
        break;
      case "SPACE_ALPHA_NUMERIC":
        this.control.setValue(value.filter(key => FieldConstraintDirective.spacedAlphaNumeric(key)).join(''));
        break;
      case "ALPHA":
        this.control.setValue(value.filter(key => FieldConstraintDirective.onlyAlpha(key)).join(''));
        break;
      case "EMAIL":
        this.control.setValue(value.filter(key => FieldConstraintDirective.email(key)).join(''));
        break;
    }
    let max = this.controlValue.substring(0, this.fieldMaxLength);
    this.control.setValue(max);
  }

  get controlValue() {
    return this.control?.value || '';
  }

  static onlyNumbers = (value: any): boolean => {
    if (/[0-9]/.test(value)) {
      return true;
    }
    return false;
  }


  static alphaNumeric = (charCode: any): boolean => {
    if (/[a-zA-Z0-9]/.test(charCode)) {
      return true;
    }
    return false;
  }


  static onlyAlpha = (charCode: any): boolean => {
    if (/[a-zA-Z]/.test(charCode)) {
      return true;
    }
    return false;
  }

  static spacedAlphaNumeric = (charCode: any): boolean => {
    if (/[A-Za-z\d\s]/g.test(charCode)) {
      return true;
    }
    return false;
  }

  static email = (charCode: any): boolean => {
    if (/[a-zA-Z0-9_\.-\@]/.test(charCode)) {
      return true;
    }
    return false;
  }
}
