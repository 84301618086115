import {EntityType} from "@features/models/entity-type";
import {AuthRequest} from "@features/models/auth-request";
import {environment} from "@environments/environment.dev";
import {RequiredFile} from "@features/models/required-file";
import {Configuration} from "@features/models/configuration";
import {AddressHolder} from "@features/models/address-holder";
import {QuestionModel} from "@features/models/questions-detail";
import {BusinessDetail} from "@features/models/business-detail";
import {StepArrayModel} from "@features/models/step-array.model";
import {PersonalDetails} from "@features/models/personal-details";
import {FinancialDetail} from "@features/models/financial-detail";
import {LookupModel, Product} from "@features/models/lookup.model";
import {ProductAndPricing} from "@features/models/product-and-pricing";

export interface ApplicationState {
    idType: string;
    product: Product,
    lookup: LookupModel;
    stepCurrent: number;
    config: Configuration
    clientType: EntityType;
    authRequest: AuthRequest;
    stepData: StepArrayModel;
    questions: QuestionModel;
    filesRequired: RequiredFile[];
    businessDetails: BusinessDetail
    personalDetails: PersonalDetails;
    financialDetails: FinancialDetail,
    productPricing: ProductAndPricing;
    relatedParties: PersonalDetails[];
    currentRelatedParty: PersonalDetails;
    moveRelatedParties: PersonalDetails[];
    copyPersonalDetails: PersonalDetails;
    answers: { answerId: string, questionId: string }[];
    // HELPERS
    ref: string;
    evolveDetails: any;
    addressHolder: AddressHolder;
    hasEvolve: boolean | undefined;
    openEvolve: boolean | undefined;
    partyAddressHolder: AddressHolder;
    islamicEvolve: boolean | undefined;
    evolveDeposit: boolean | undefined;
}

export const INIT_STATE: ApplicationState = {
    hasEvolve: undefined,
    filesRequired: [],
    relatedParties: [],
    evolveDeposit: undefined,
    moveRelatedParties: [],
    authRequest: new AuthRequest(),
    addressHolder: {} as AddressHolder,
    partyAddressHolder: {} as AddressHolder,
    stepCurrent: environment.stepCurrent || 0,
} as ApplicationState;
