import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {environment} from '@environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CoreService extends BehaviorSubject<boolean> {

    readonly loading$ = this.asObservable();

    constructor(
        private _http: HttpClient,
    ) {
        super(false);
    }

    getResource<T>(uri: string, spinner: string = 'on'): Observable<T> {
        return this._http.get<T>(uri, {
            headers: {
                spinner: spinner
            }
        });
    }

    get<T>(uri: string, spinner: string = 'on'): Observable<T> {
        return this.getResource<T>(`${environment.apiURL}${uri}`, spinner);
    }

    post<T>(uri: string, model: any, spinner: string = 'on'): Observable<T> {
        return this._http.post<T>(`${environment.apiURL}${uri}`, model,
            {
                headers: {
                    spinner: spinner
                }
            });
    }

    show(): void {
        this.next(true);
    }

    hide(): void {
        this.next(false);
    }

    uploadDocument(uri: string, formData: FormData, headers: {
        headers: HttpHeaders | {
            [header: string]: string | string[];
        }
    }) {
        return this._http.post(`${environment.docURL}${uri}`, formData, headers).toPromise();
    }
}
