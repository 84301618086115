import {BusinessEvolvePricePlan} from "@features/models/lookup.model";
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-account-card',
    templateUrl: './account-card.component.html'
})
export class AccountCardComponent {

    @Input() plan: BusinessEvolvePricePlan;
    @Output() planSelected = new EventEmitter<BusinessEvolvePricePlan>();

    constructor() {
    }

    selected() {
        this.planSelected.emit(this.plan);
    }
}
