<div class="apply-card" *ngIf="( product | async )">
    <div class="apply-card-image">
        <img src="assets/icons/google/white-logo.svg">
    </div>
    <mat-card class="apply-card-border" style="box-shadow: none !important;">
        <div class="apply-title">{{(config | async).applicationTitle }}</div>
        <div class="apply-detail">{{ (config | async).applicationInformation }}</div>
        <br/>
        <button (click)="clickApply()" class="button-active">Apply now</button>
    </mat-card>
</div>

