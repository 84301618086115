<form [formGroup]="formGroup">
    <app-switch
            negativeLabel="No"
            positiveLabel="Yes"
            label="Are you a shareholder?"
            [control]="formGroup.controls['hasShares']">
    </app-switch>
    <div *ngIf="formGroup.controls['hasShares'].value===true">
        <mat-form-field floatLabel="always">
            <mat-label>Percentage of shares</mat-label>
            <input matInput
                   appFieldConstraint
                   [maxlength]="maxLength"
                   fieldType="ONLY_NUMERIC"
                   [fieldMaxLength]="maxLength"
                   formControlName="sharesValue"
                   placeholder="Enter percentage of shares"
                   [control]="formGroup.controls['sharesValue']"/>
            <span matSuffix>%</span>
        </mat-form-field>
        <app-validation-error
                [controller]="formGroup.controls['sharesValue']"
                [message]="'Share percentage is required and the total cannot exceed 100'">
        </app-validation-error>
    </div>
</form>
