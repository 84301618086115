import {Observable} from "rxjs";
import {Component} from '@angular/core';
import {NgRedux, select} from "@angular-redux/store";
import {PREVIOUS_STEP} from "@features/state/actions";
import {ApplicationState} from "@features/state/application-state";
import {StepArrayModel} from "@features/models/step-array.model";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent {

    @select((store: ApplicationState) => store?.ref) stepTitle: Observable<string>;
    @select((store: ApplicationState) => store.stepCurrent) stepCurrent: Observable<number>;
    @select((store: ApplicationState) => store.stepData?.hasBack) hasBack: Observable<boolean>;
    @select((store: ApplicationState) => store.stepData?.headerText) headerText: Observable<string>;
    @select((store: ApplicationState) => store.clientType?.name) stepDescription: Observable<string>;
    @select((store: ApplicationState) => store.config?.stepArray) stepArray: Observable<StepArrayModel[]>;

    constructor(
        private store: NgRedux<ApplicationState>,
    ) {
    }

    navigateToPreviousStep() {
        this.store.dispatch(PREVIOUS_STEP());
    }
}
