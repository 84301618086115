<!--
 ACCOUNT OPTIONS
-->
<div *ngIf="(page$ | async)===0">
    <form [formGroup]="formGroup">
        <app-switch
                negativeLabel="No"
                positiveLabel="Yes"
                label="Do you have an existing Absa Business Evolve account?"
                [control]="formGroup.controls['bankQuestion']">
        </app-switch>
        <div *ngIf="hasEvolve | async">
            <app-switch
                    negativeLabel="No"
                    positiveLabel="Yes"
                    label="Do you wish to deposit your profits into your Business Evolve account?"
                    [control]="formGroup.controls['evolveQuestion']">
            </app-switch>
        </div>
        <div *ngIf="formGroup.controls['bankQuestion'].value===false">
            <app-switch
                    negativeLabel="No"
                    positiveLabel="Yes"
                    [control]="formGroup.controls['openEvolveQuestion']"
                    label="Would you like Absa Business Evolve account?"
                    labelHint="Get a business account that evolves as your business grows. Choose from our 7 evolve packages">
            </app-switch>
        </div>

        <div *ngIf="formGroup.controls['openEvolveQuestion'].value===true">
            <app-switch
                    negativeLabel="No"
                    positiveLabel="Yes"
                    [control]="formGroup.controls['islamicEvolveQuestion']"
                    label="Would you like this account to be an Islamic Business Evolve account?">
            </app-switch>
        </div>
        <!---
          GENERAL ACCOUNT DETAILS
        -->
        <div *ngIf="showAccountForm()">
            <!-- DEPOSIT FORM-->
            <mat-form-field floatLabel="always">
                <mat-label>Account name</mat-label>
                <input matInput
                       maxlength="35"
                       placeholder="Account name"
                       formControlName="accountName"
                       appFieldConstraint
                       [fieldMaxLength]="80"
                       fieldType="SPACE_ALPHA_NUMERIC"
                       [control]="formGroup.controls['accountName']"/>
            </mat-form-field>
            <mat-form-field floatLabel="always">
                <mat-label>Account number</mat-label>
                <input matInput
                       maxlength="11"
                       placeholder="Account number"
                       formControlName="accountNumber"
                       appFieldConstraint
                       [fieldMaxLength]="11"
                       fieldType="ONLY_NUMERIC"
                       [control]="formGroup.controls['accountNumber']"/>
            </mat-form-field>
            <mat-form-field floatLabel="always">
                <mat-label>Bank name</mat-label>
                <mat-select
                        formControlName="bankName"
                        placeholder="Select your bank"
                        [compareWith]="compareOptions">
                    <mat-option *ngFor="let item of banks | async" [value]="item.description">
                        {{ item.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field floatLabel="always">
                <mat-label>Branch code</mat-label>
                <input matInput
                       maxlength="8"
                       placeholder="Enter your branch code"
                       formControlName="branchCode"
                       appFieldConstraint
                       [fieldMaxLength]="8"
                       fieldType="ONLY_NUMERIC"
                       [control]="formGroup.controls['branchCode']">
            </mat-form-field>
            <mat-form-field floatLabel="always">
                <mat-label>Branch name</mat-label>
                <input matInput
                       maxlength="35"
                       placeholder="Enter your branch name"
                       formControlName="branchName"
                       appFieldConstraint
                       [fieldMaxLength]="35"
                       fieldType="SPACE_ALPHA_NUMERIC"
                       [control]="formGroup.controls['branchName']">
            </mat-form-field>
        </div>
        <div class="navigation-bottom">
            <button
                    type="button"
                    class="button-active"
                    (click)="clickNext()"
                    [disabled]="selectionsInvalid()">
                Next
            </button>
            <br/>
            <br/>
        </div>
    </form>
</div>
<div *ngIf="(page$ | async)===1">
    <div *ngFor="let plan of plans()">
        <app-account-card
                [plan]="plan"
                (planSelected)="selectPlan($event)">
        </app-account-card>
    </div>
</div>
<div *ngIf="(page$ | async)===2">
    <app-account-details
            [plan]="plan"
            [terms]="evolveProduct.businessEvolveValueAddedServices">
    </app-account-details>
    <div class="navigation-bottom">
        <button type="button" class="button-inactive" (click)="viewAllProducts()">View all products</button>
        <br/>
        <br/>
    </div>
    <div class="navigation-bottom">
        <button type="button" class="button-active" (click)="viewCreditsForm()">Apply for Business Evolve</button>
        <br/>
        <br/>
    </div>
</div>
<!--
    CREDITS FORM
-->
<div *ngIf="(page$ | async)===3">
    <form [formGroup]="creditsFormGroup">
        <mat-form-field floatLabel="always">
            <mat-label>Purpose of account</mat-label>
            <mat-select
                    formControlName="accountPurpose"
                    placeholder="Select purpose of account"
                    [compareWith]="compareOptions">
                <mat-option
                        [value]="purpose.purpose"
                        *ngFor="let purpose of accountPurposes | async">
                    {{ purpose.purpose }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Expected number of credits per month</mat-label>
            <input matInput
                   maxlength="8"
                   placeholder="Enter number of credits per month"
                   formControlName="numberOfCreditsPerMonth"
                   appFieldConstraint
                   [fieldMaxLength]="8"
                   fieldType="ONLY_NUMERIC"
                   [control]="creditsFormGroup.controls['numberOfCreditsPerMonth']">
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Value of credits per month in rands</mat-label>
            <input matInput
                   maxlength="8"
                   placeholder="Enter value of credits per month"
                   formControlName="valueOfCreditsPerMonth"
                   appFieldConstraint
                   [fieldMaxLength]="8"
                   fieldType="ONLY_NUMERIC"
                   [control]="creditsFormGroup.controls['valueOfCreditsPerMonth']">
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>How do you receive your money?</mat-label>
            <input matInput
                   maxlength="100"
                   placeholder="Enter how do you receive your money"
                   formControlName="receiveMoney"
                   appFieldConstraint
                   [fieldMaxLength]="100"
                   fieldType="ALPHA_NUMERIC"
                   [control]="creditsFormGroup.controls['receiveMoney']">
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Where will your money be coming from?</mat-label>
            <input matInput
                   maxlength="100"
                   placeholder="Enter where will your money be coming from"
                   formControlName="sourceOfMoney"
                   appFieldConstraint
                   [fieldMaxLength]="100"
                   fieldType="ALPHA_NUMERIC"
                   [control]="creditsFormGroup.controls['sourceOfMoney']">
        </mat-form-field>
        <div class="navigation-bottom">
            <button
                    type="button"
                    class="button-active"
                    (click)="viewDebitsForm()"
                    [disabled]="creditsFormGroup.invalid">
                Next
            </button>
            <br/>
            <br/>
        </div>
    </form>
</div>
<!--
    DEBIT  FORM
-->
<div *ngIf="(page$ | async)===4">
    <form [formGroup]="debitsFormGroup">
        <mat-form-field floatLabel="always">
            <mat-label>Number of debits per month</mat-label>
            <input matInput
                   maxlength="8"
                   placeholder="Enter number of debits per month"
                   formControlName="numberOfDebitsPerMonth"
                   appFieldConstraint
                   [fieldMaxLength]="8"
                   fieldType="ONLY_NUMERIC"
                   [control]="debitsFormGroup.controls['numberOfDebitsPerMonth']">
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Number of supplier payment per month</mat-label>
            <input matInput
                   maxlength="8"
                   placeholder="Enter number of supplier payment per month"
                   formControlName="numberOfSupplierPaymentPerMonth"
                   appFieldConstraint
                   [fieldMaxLength]="8"
                   fieldType="ONLY_NUMERIC"
                   [control]="debitsFormGroup.controls['numberOfSupplierPaymentPerMonth']">
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Where are your supplier based</mat-label>
            <mat-select
                    formControlName="supplierCountry"
                    placeholder="Select where supplier based"
                    [compareWith]="compareOptions">
                <mat-option
                        [value]="country.code"
                        *ngFor="let country of countries | async">
                    {{ country.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Number of staff members</mat-label>
            <input matInput
                   maxlength="8"
                   placeholder="Enter number of supplier payment per month"
                   formControlName="numberOfStaffMembers"
                   appFieldConstraint
                   [fieldMaxLength]="8"
                   fieldType="ONLY_NUMERIC"
                   [control]="debitsFormGroup.controls['numberOfStaffMembers']">
        </mat-form-field>

        <mat-form-field floatLabel="always">
            <mat-label>Staff pay date</mat-label>
            <mat-select
                    formControlName="staffPayDate"
                    placeholder="Select staff pay date"
                    [compareWith]="compareOptions">
                <mat-option *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,
                                                 11,12,13,14,15,16,17,18,19,20,
                                                    21,22,23,24,25,26,27,28,29,30,31]"
                            [value]="item">
                    {{ item }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Value of debit orders in rands per month</mat-label>
            <input matInput
                   maxlength="8"
                   placeholder="Enter value of debit orders"
                   formControlName="valueOfDebitOrders"
                   appFieldConstraint
                   [fieldMaxLength]="8"
                   fieldType="ONLY_NUMERIC"
                   [control]="debitsFormGroup.controls['valueOfDebitOrders']">
        </mat-form-field>
        <mat-form-field floatLabel="always">
            <mat-label>Reason for debit orders</mat-label>
            <input matInput
                   maxlength="100"
                   placeholder="Enter reason debit orders"
                   formControlName="reasonOfDebitOrders"
                   appFieldConstraint
                   [fieldMaxLength]="100"
                   fieldType="ALPHA_NUMERIC"
                   [control]="debitsFormGroup.controls['reasonOfDebitOrders']">
        </mat-form-field>
        <div class="navigation-bottom">
            <button
                    type="button"
                    class="button-active"
                    (click)="applyBusinessEvolve()"
                    [disabled]="debitsFormGroup.invalid">
                Next
            </button>
            <br/>
            <br/>
        </div>
    </form>
</div>