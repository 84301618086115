import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {Country} from "@features/models/country";
import {NgRedux, select} from "@angular-redux/store";
import {FormHelper} from "@features/helper/form-helper";
import {CoreService} from "@core/services/core.service";
import {Nationality} from "@features/models/nationality";
import {MaritualStatus} from "@features/models/maritual-status";
import {Designation, Gender} from "@features/models/lookup.model";
import {PersonalDetails} from "@features/models/personal-details";
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {NEXT_STEP, UPDATE_PERSONAL_DATA} from "@features/state/actions";

@Component({
    selector: 'app-step-three',
    templateUrl: './step-three.component.html'
})
export class StepThreeComponent implements OnInit {
    model: any;
    formGroup: FormGroup;

    //@select((store: ApplicationState) => store.authRequest.idType) idType: Observable<string>;
    @select((store: ApplicationState) => store.lookup.genders) modelGender: Observable<Gender[]>;
    @select((store: ApplicationState) => store.lookup.designations) designations: Observable<Designation[]>;
    @select((store: ApplicationState) => store.lookup.nationalities) modelNationality: Observable<Nationality[]>;
    @select((store: ApplicationState) => store.lookup.countriesOfOrigin) modelCountryOfBirth: Observable<Country[]>;
    @select((store: ApplicationState) => store.lookup.maritalStatuses) modelMaritualStatus: Observable<MaritualStatus[]>;
    @select((store: ApplicationState) => store.lookup.countriesOfResidence) modelCountryOfResidence: Observable<Country[]>;

    constructor(
        private router: Router,
        private spinner: CoreService,
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
    ) {
        this.model = this.store.getState().copyPersonalDetails;
    }

    ngOnInit(): void {
        let model = this.store.getState().personalDetails;
        this.formGroup = this.builder.group({
            gender: [model.gender, [Validators.required]],
            lastName: [model.lastName, [Validators.required]],
            firstName: [model.firstName, [Validators.required]],
            nationality: [model.nationality, [Validators.required]],
            maritalStatus: [model.maritalStatus, [Validators.required]],
            countryOfBirth: [model.countryOfBirth, [Validators.required]],
            countryOfResidence: [model.countryOfResidence, [Validators.required]],
            idNumber: [model.idNumber, [...(this.isPassport ? FormHelper.PASSPORT_VALIDATIONS : FormHelper.ID_VALIDATIONS)]],
        });
        if (!this.isSoleTrader()) {
            this.formGroup.addControl('authorisedSignatory',
                this.builder.control('', [Validators.required]));
            this.formGroup.addControl('sharePercentage',
                this.builder.control(model.sharePercentage, [Validators.required]));
        }
        if (this.isPassport) {
            this.formGroup.addControl('passportDetails',
                this.builder.control(null, [Validators.required]));
        }
    }

    clickNext(): void {
        let value = this.formGroup.getRawValue();
        let auth = this.store.getState().authRequest;
        let model = this.store.getState().personalDetails;
        let request = {...model, ...value} as PersonalDetails;
        this.store.dispatch(UPDATE_PERSONAL_DATA({
            ...request,
            roles: !this.isSoleTrader() ? 'APPLICANT' : model.roles,
            contactInformation: {
                ...request.contactInformation,
                email: auth.emailAddress,
                cellphone: auth.cellnumber,
                preferredCommunicationMethod: auth.communicationMethod,
            }
        }));
        this.store.dispatch(NEXT_STEP());
    }

    compareOptions(object: any, selected: any): boolean {
        return object != null && selected != null && object == selected;
    }

    fieldHasValue(key: string): boolean {
        return !!this.model[key];
    }

    getAppearance(key: string): any {
        return this.fieldHasValue(key) ? 'none' : 'standard';
    }

    isInvalid(): boolean {
        return Object.keys(this.formGroup.controls).filter(key => this.formGroup.controls[key].invalid).length > 0;
    }

    isSoleTrader(): boolean {
        return FormHelper.isSoleProprietor(this.store.getState().clientType);
    }

    get isPassport(): boolean {
        return this.store.getState().authRequest.idType == '03';
    }
}
