import {Component, Input, OnInit} from '@angular/core';
import {PersonalDetails} from "@features/models/personal-details";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-related-party',
    templateUrl: './related-party.component.html'
})
export class RelatedPartyComponent implements OnInit {

    @Input() index: number;
    formGroup: FormGroup;
    @Input() party: PersonalDetails;

    constructor(private builder: FormBuilder) {
    }

    ngOnInit(): void {
        let config: any = {};
        config['lastName_' + this.index] = [this.party.lastName];
        config['idNumber_' + this.index] = [this.party.idNumber];
        config['firstName_' + this.index] = [this.party.firstName];
        this.formGroup = this.builder.group(config);
    }

    getName(name: string): string {
        return `${name}_${this.index}`;
    }

    getValue(name: string): any {
        let value: any = {};
        let key = this.getName(name);
        value[key] = '_';
        return value;
    }

}
