import {Observable} from "rxjs";
import {NgRedux, select} from "@angular-redux/store";
import {PERSONAL_DATA} from "@features/state/actions";
import {FormHelper} from "@features/helper/form-helper";
import {PersonalDetails} from "@features/models/personal-details";
import {ApplicationState} from "@features/state/application-state";
import {FeaturesService} from "@features/services/features.service";
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PagesControllerService} from "@features/services/pages-controller.service";

@Component({
    selector: 'app-entity-party',
    templateUrl: './entity-party.component.html'
})
export class EntityPartyComponent implements OnInit {

    formGroup: FormGroup;
    @Output() partySelected = new EventEmitter<boolean>();
    @select((store: ApplicationState) => store.relatedParties) relatedParties: Observable<PersonalDetails[]>;

    constructor(
        private service: FeaturesService,
        private builder: FormBuilder,
        private store: NgRedux<ApplicationState>,
        public controller: PagesControllerService,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.builder.group({
            party: ['', [Validators.required]],
        });
    }

    clickNext() {
        let value = this.formGroup.getRawValue();
        let party = this.store.getState().relatedParties.find(e => e.id == value.party);
        this.store.dispatch(PERSONAL_DATA(party));
        this.partySelected.next(true);
    }

    clickPrevious() {
        this.controller.showEntityRole();
    }

    isInvalid(): boolean {
        return FormHelper.isInvalid(this.formGroup);
    }

    getDetails(party: PersonalDetails): string {
        if (party.firstName || party.lastName) {
            return `${party.firstName} ${party.lastName}`;
        } else {
            return `${party.idNumber} (${party.roles})`;
        }
    }
}