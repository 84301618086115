<!--
<div class="upload-title">
    {{ this.file.category }}
</div>
-->
<div class="upload-button">
    <div *ngIf="isTooBig" class="upload-size-error">
        Selected file is over {{MX_FILE}}MB
    </div>
    <div *ngIf="!file.uploaded">
        <button (click)="getFile.click()" class="button-file-upload" *ngIf="!file.file">
            <app-file-details
                    [right]="false"
                    icon="document"
                    [details]="description">
            </app-file-details>
        </button>
        <button (click)="delete()" class="button-file-remove" *ngIf="file.file">
            <app-file-details
                    icon="trash"
                    [file]="file.file"
                    [details]="description">
            </app-file-details>
        </button>
    </div>
    <div *ngIf="file.uploaded">
        <button (click)="delete()" class="button-file-remove">
            <app-file-details
                    icon="tick"
                    [file]="file.file"
                    [details]="description">
            </app-file-details>
        </button>
    </div>
    <input hidden type="file" #getFile (change)="compileFile($event)"/>
</div>